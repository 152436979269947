@import "../_config.scss";


.comp-PatrimonioCard {
    overflow: hidden;
    border-radius: 22px;
    overflow: hidden;
    height: 450px;
    box-shadow: 0px 12px 24px -10px rgba(0, 0, 0, 0.05);

    @include large {
        height: 430px;
    }

    @include normal_down {
        height: 430px;
    }

    @include mini {
        height: 420px;
    }

    &:hover {
        .img-fundo {
            transform: scale(1.1);
            transition: all 500ms ease-in-out;
        }
    }

    &__wrapper {
        /*&:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            height: 35%;
            width: 100%;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.56) 41.18%, rgba(0, 0, 0, 0.80) 100%);
            z-index: 2;
        }*/
    }

    &__fundo {
        height: 100%;
        width: 100%;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform: scale(1);
        transition: all 500ms ease-in-out;
    }

    &__icone {
        height: 88px;
        width: 88px;
        padding: 20px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--color-principal);
        position: absolute;
        top: 24px;
        right: 24px;
        border-radius: 50%;
    }

    &__info {
        position: absolute;
        color: white;
        padding: 40px 40px;
        z-index: 5;
        bottom: 0;
        width: calc(100% - 80px);

        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.56) 41.18%, rgba(0, 0, 0, 0.80) 100%);
            z-index: 2;
        }

        .titulo {
            line-height: 130%;
            z-index: 3;
        }

        .subtitulo {
            padding-top: 10px;
            margin-bottom: 24px;
            line-height: 150%;
            z-index: 3;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }

        maicontaghelper, svg {
            width: 30px;
            height: 20px;
            display: flex;
            z-index: 3;
        }
    }
}