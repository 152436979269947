@import "../../assets/dev/css/_config.scss";
$bannerHeight: 766px;

.HomepageBanners {
    padding-top: 95px;
    min-height: $bannerHeight;

    @include normal_down {
        min-height: 650px;
    }

    @include small_down {
        min-height: 550px;
    }

    &__wrapper {
        overflow: hidden;
        min-height: $bannerHeight;

        @include normal_down {
            min-height: 650px;
        }

        @include small_down {
            min-height: 550px;
        }
    }

    &__slide {
        padding: 77px 0 77px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        min-height: $bannerHeight;
        height: auto;

        @include normal_down {
            min-height: 650px;
        }

        @include small_down {
            min-height: 550px;
        }


        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            height: 65%;
            width: 100%;
            z-index: 1;
            /*background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.57) 100%);*/
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.48) 22.39%, rgba(0, 0, 0, 0.60) 100%);
        }

        @include normal {
            min-height: 650px;
        }

        @include small_down {
            min-height: 550px;
        }
    }

    &__media {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;

        img,
        video {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    &__textos {
        color: white;
        z-index: 5;
        max-width: 960px;
        width: 70%;
        /*padding-left: 7%;*/


        strong {
            color: #ACFF77;
            @include fontresponsiva(74, 1, 300, var(--alt-font-family), 0, 40);
        }

        .icon-holder {
            height: 88px;
            width: 88px;
            background-color: var(--color-principal);
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-content: center;
            padding: 20px;
            box-sizing: border-box;
            margin-bottom: 20px;
        }
    }

    &__titulo {
        padding-bottom: 16px;
        max-width: 70%;

        @include mini {
            max-width: 100%;
        }
    }

    &__subtitulo {
        /*padding-bottom: 45px;*/
        display: block;
        max-width: 40%;
        line-height: 130%;

        @include mini {
            max-width: 100%;
        }
    }

    $urltransition: 300ms ease-in-out;

    &__url {
        display: flex;
        align-items: center;
        gap: 20px;
        transition: $urltransition;
        text-transform: uppercase;
        cursor: pointer;
        color: white;

        maicontaghelper {
            width: 29px;
            height: 25px;
            display: flex;
            transition: $urltransition;

            svg {
                width: 29px;
                display: flex;
                transition: $urltransition;
            }
        }

        &:hover {
            font-weight: 600;
            transition: $urltransition;

            maicontaghelper {
                width: 34px;
                height: 25px;
                display: flex;
                transition: $urltransition;

                svg {
                    width: 34px;
                    display: flex;
                    transition: $urltransition;
                }
            }
        }
    }

    .swiper-pagination {

        .swiper-pagination-bullet {
            width: 4px;
            height: 4px;
            background: rgba(255, 255, 255, 0.50);

            &.swiper-pagination-bullet-active {
                background-color: var(--color-principal);
                height: 4px;
                border-radius: 100px;
                width: 35px;
                align-self: stretch;
            }
        }
    }

    .open-video {
        width: 40px;
        height: 40px;
        position: absolute;
        border-radius: 50%;
        background-color: var(--color-principal);
        bottom: 40px;
        right: 40px;
        z-index: 4;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .swiper-pagination {
        z-index: 3;
    }
}

.featherlight.video {
    .featherlight-content {
        max-width: 100%;
        height: 100%;

        video {
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            bottom: 0;
            margin: auto;
            max-height: 100%;
        }
    }

    .featherlight-inner {
        height: 100%;
        box-sizing: border-box;
        display: flex;

        .closevid {
            z-index: 2;
            position: absolute;
            right: 0;
            top: 0;
            height: 50px;
            width: 50px;

            .icon-ma {
                height: 50px;
                width: 50px;
                cursor: pointer;
            }
        }
    }
}
