@import "../../assets/dev/css/_config.scss";

.ConteudoTextoImagem {
    background-color: var(--color-alternativa);

    &__wrapper {
        display: flex !important;
        align-items: center;
        padding: 5% 0;

        @include small_down {
            flex-direction: column;
        }

        .info-holder {
            width: 50%;
            padding-right: 12%;
            box-sizing: border-box;

            @include small_down {
                width: 100%;
                margin-bottom: 5%;
            }

            div {

                &.resumo {
                    padding-bottom: 20px;
                    line-height: 150%;
                }

                &.texto {
                    display: block;
                    line-height: 150%;
                }
            }
        }

        .media-holder {
            height: 770px;
            width: 50%;
            max-width: 720px;
            overflow: hidden;
            margin: auto;

            @include small_big {
                max-width: 616px;
                height: 680px;
            }

            @include small_down {
                width: 100%;
                height: 580px;
            }

            @include mini {
                height: 380px;
            }

            img, video {
                position: absolute;
                height: 100%;
                width: 100%;
                top: 0;
                left: 0;
                object-fit: cover;
            }
        }
    }
}