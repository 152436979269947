@import "../../assets/dev/css/_config.scss";

.NoticiasEventosListagem {
    padding: 33px 0 120px;
    margin-top: 95px !important;

    .titulos {
        margin-bottom: 48px;
    }

    .lista {
        display: flex;
        flex-wrap: wrap;
        gap: 1.5%;

        .noticia-card {
            margin-bottom: 16px;
            width: 32.33%;
            margin-bottom: 2%;

            @include mini {
                width: 49%;
            }

            @media only screen and (max-width : 470px) {
                width: 100%;
                margin-bottom: 2%;
            }

            &__link {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 1;
            }

            &__img {
                padding-bottom: 279px / 396px * 100%;
                border-radius: 16px;
                margin-bottom: 16px;

                img {
                    border-radius: 16px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            &__info {
                display: flex;
                padding: 0 8px;
                box-sizing: border-box;
                flex-direction: column;

                .data {
                   /* color: #181818;
                    opacity: 0.5;
                    text-transform: uppercase;
                    margin-bottom: 6px;*/
                   display: none;
                }

                p,
                h4 {
                    display: -webkit-box;
                    max-width: 100%;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                }

                h4 {
                    margin-bottom: 4px;
                    line-height: 130%;
                }

                p {
                    line-height: 150%;
                }

                .link {
                    margin-top: 16px;
                    text-transform: uppercase;
                    text-decoration: underline;
                    color: #0B5C42;
                }
            }
        }
    }
}