@import "../../assets/dev/css/_config.scss";

.Footer {
    padding: 50px 0 24px;
    border-top: 1px solid #F7F7F7;

    &__inner {
        display: flex;
        justify-content: space-between;
        gap: 4%;
        padding-bottom: 8%;

        @include small_down {
            flex-direction: column;
            gap: 30px;
            padding-bottom: 60px;
        }

        .Footer__left {
            width: 35%;
            max-width: 300px;

            @include small_down {
                width: 100%;
            }

            .social {
                padding-top: 32px;
                display: flex;
                gap: 10px;

                a {
                    padding: 13px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;
                    background: #F7F7F7;
                    transition: 300ms all ease-in-out;
                    opacity: 1;

                    &:hover {
                        opacity: 0.7;
                    }
                }
            }

            .imagemlink {
                display: flex;
                flex-flow: wrap;
                margin-top: 10px;
                width: 100%;

                @include mini {
                    margin-top: 20px;
                }

                &__item {
                    display: flex;
                    height: 81px;
                    width: auto;
                    max-width: 50%;

                    @include mini {
                        max-width: 100%;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
            }
        }

        .Footer__right {
            width: 60%;

            @include small_down {
                width: 100%;
            }

            .Footer__menu {
                display: flex;
                gap: 10%;

                @include small {
                    gap: 5%;
                }

                .menu-title {
                    text-transform: uppercase;
                    display: block;
                    margin-bottom: 24px;
                }

                .page-link {
                    margin-bottom: 8px;

                    p {
                        margin-bottom: 10px;
                        line-height: 120%;
                    }

                    a {
                        transition: 300ms all ease-in-out;
                        opacity: 1;

                        &:hover {
                            opacity: 0.7;
                        }
                    }
                }

                @include small_down {
                    .menu-col:nth-child(-n+2) {
                        display: none;
                    }
                }
            }
        }
    }

    .footnotes {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 16px;
        border-top: 1px solid rgba(0, 0, 0, 0.10);

        @include small_down {
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
        }

        .link-ma {
            transition: 300ms all ease-in-out;

            &:hover {
                color: var(--color-principal);
            }
        }

        .polticas {
            display: flex;
            align-items: center;
            gap: 20px;

            a {
                color: var(--color-principal);
                text-decoration: underline;
                transition: 300ms all ease-in-out;
                opacity: 1;

                &:hover {
                    opacity: 0.7;
                }
            }

            a:first-of-type:after {
                content: "";
                right: -10px;
                height: 12px;
                top: 2px;
                width: 1px;
                background-color: var(--color-principal);
                position: absolute;
            }
        }
    }
}
