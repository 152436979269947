@import "../../assets/dev/css/_config.scss";

$urltransition: 300ms ease-in-out;

.HomepageCTA {
    overflow: hidden;

    .open-video {
        width: 40px;
        height: 40px;
        position: absolute;
        border-radius: 50%;
        background-color: var(--color-principal);
        bottom: 40px;
        right: 40px;
        z-index: 4;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .media-holder {
        position: absolute;
        height: 100%;
        width: 100%;

        video {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
        /*&:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            z-index: 2;
            background: rgba(11, 92, 66, 0.75);
        }*/
    }

    .info-holder {
        padding: 3.5% 0 33.5% 0;
        max-width: 66%;
        margin-left: 4%;
        z-index: 5;
        text-align: left;

        strong {
            color: var(--color-extra);
            @include fontresponsiva(62, 1, 300, var(--alt-font-family), 0, 32);
        }

        &__titulo {
            display: block;
            color: white;
            /*padding-bottom: 16px;*/
            line-height: 100%;
        }

        &__subtitulo {
            display: block;
            color: white;
            padding-bottom: 40px;
            line-height: 130%;
            max-width: 860px;
            /*margin: 0 auto;*/
        }

        .link-holder {
            display: flex;
            align-items: center;
            transition: $urltransition;
            justify-content: center;
            gap: 20px;
            display: inline-flex;
            cursor: pointer;

            maicontaghelper, svg {
                width: 30px;
                height: 25px;
                transition: $urltransition;
            }

            &:hover {
                transition: $urltransition;

                .info-holder__link {
                    font-weight: 600;
                }

                maicontaghelper, svg {
                    width: 35px;
                    transition: $urltransition;
                }
            }
        }

        &__link {
            display: block;
            transition: $urltransition;
            color: white;
            text-transform: uppercase;
        }
    }
}

.featherlight.videohome {
    .featherlight-content {
        max-width: 100%;
        height: 100%;

        video {
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            bottom: 0;
            margin: auto;
            max-height: 100%;
        }
    }

    .featherlight-inner {
        height: 100%;
        box-sizing: border-box;
        display: flex;

        .closevid {
            z-index: 2;
            position: absolute;
            right: 0;
            top: 0;
            height: 50px;
            width: 50px;

            .icon-ma {
                height: 50px;
                width: 50px;
                cursor: pointer;
            }
        }
    }
}
