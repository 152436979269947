@import "../../assets/dev/css/_config.scss";

.ConteudoBanner {
    margin-top: 95px;
    padding-top: 25%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 55px;
    min-height: 334px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    &:before {
        content: "";
        width: 100%;
        height: 40%;
        position: absolute;
        bottom: 0;
        left: 0;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.80) 78.04%);
    }

    .titulos {
        z-index: 1;
        color: #fff;
    }

    .titulo {
        line-height: 120%;
    }

    .desc {
        line-height: 130%;
    }
}
