@import "../../assets/dev/css/_config.scss";

.NoticiasEventosBanner {

    .banner-principal {
        padding-top: 25%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        padding-bottom: 55px;

        &:before {
            content: "";
            width: 100%;
            height: 30%;
            position: absolute;
            bottom: 0;
            left: 0;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.70) 78.04%);
        }

        .titulos {
            z-index: 1;
            display: flex;
            align-items: flex-end;
            justify-content: flex-start;
            gap: 18px;
            color: #fff;

            .titulo__info {
                min-height: 120px;
            }

            .breadcrumb {
                margin-bottom: 8px;
                display: flex;
                align-items: center;
                gap: 5px;
                color: #bdbcbc;

                a {
                    display: flex;
                    align-items: center;
                    color: #bdbcbc !important;
                    text-decoration: underline;
                    gap: 3px;

                    .icon-ma {
                        width: 12px;
                        transform: rotate(180deg);
                    }
                }
            }

            .icon {
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                background-color: var(--color-principal);
                height: 88px;
                width: 88px;

                img {
                    height: 100%;
                    width: 100%;
                    max-height: 48px;
                    max-width: 48px;
                    object-fit: contain;
                }

                img {
                    height: 48px;
                }

                &.mobile {
                    display: none;
                }

                @include mini {
                    display: none;

                    &.mobile {
                        display: flex;
                        width: 88px;
                        box-sizing: border-box;
                    }
                }
            }

            .page-title {
                line-height: 120%;
            }

            .localizacao {
                /*color: var(--color-extra);
                line-height: 130%;
                text-transform: uppercase;*/
                display: none;
            }
        }
    }
}