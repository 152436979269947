﻿$font-base: 'Nunito Sans', sans-serif;
$font-alt: 'Nunito Sans', sans-serif;

$title-color: GREEN;
$text-color: BLUE;

$bg-color: #fff;

$color-black: #000;
$color-white: #fff;

$alturaHeader: 50px;

$color-base: purple;
$color-alt: red;
$color-alt2: cyan;
$color-alt3: yellow;

$color-grey11: #111;
$color-grey22: #222;
$color-grey33: #333;
$color-grey44: #444;
$color-grey55: #555;
$color-grey66: #666;
$color-grey77: #777;
$color-grey88: #888;
$color-grey99: #999;
$color-greyAA: #AAA;
$color-greyBB: #BBB;
$color-greyCC: #CCC;
$color-greyDD: #DDD;
$color-greyEE: #EEE;

$margemLateral: 30px;
$margemLateralMini: 10px;


/* CALCULA PADDING PARA A LARGURA WFULL*/

$wfullPX: 1640px;
$wfullPC: 90%;

@function decimal($v) {
    @return $v / 100% * 100;
}

$maxMediaQuery: (decimal($wfullPC) * 1920 / 100) + "px";

@mixin wpadding-half($prop) {
    #{$prop}: calc((100% - #{$wfullPX}) / 2);

    @media only screen and (max-width : $maxMediaQuery) {
        #{$prop}: calc((100% - #{$wfullPC}) / 2);
    }
}



@mixin display-inline-block() {
    display: inline-block;
    zoom: 1;
    *display: inline;
}

@mixin abs-pos ($top: 0, $right: 0, $bottom: 0, $left: 0) { //@include abs-pos(10px, 10px, 5px, 15px);
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
    position: absolute;
    margin: auto;
}

@mixin placeholder($color) { //@include placeholder(#666);
    ::-webkit-input-placeholder { /* WebKit browsers */
        color: $color;
    }

    :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        color: $color;
        opacity: 1;
    }

    ::-moz-placeholder { /* Mozilla Firefox 19+ */
        color: $color;
        opacity: 1;
    }

    :-ms-input-placeholder { /* Internet Explorer 10+ */
        color: $color;
    }
}

@mixin font($tamanho: 0, $cor: '',$fontweight: '',$familia: '') { //@include font(12px,#666,300);
    @if $familia != '' {
        font-family: $familia;
    }

    @if $cor != '' {
        color: $cor;
    }

    @if $fontweight != '' {
        font-weight: $fontweight;
    }

    @if $tamanho != 0 {
        font-size: $tamanho;
    }
}

@mixin opacity($opacity) { //@include opacity(0.8);
    zoom: 1;
    opacity: $opacity;
    $opacity-ie: $opacity * 100;
    filter: alpha(opacity=$opacity-ie); //IE8
}

@mixin background($color, $alpha) { //@include background(#fff,0.8);
    $rgba: rgba($color, $alpha);
    $ie-hex-str: ie-hex-str($rgba);
    background-color: transparent;
    background-color: $rgba;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#{$ie-hex-str},endColorstr=#{$ie-hex-str});
    zoom: 1;
}

@mixin clearfix() { //@include clearfix();
    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }
}


@mixin mini {
    @media only screen and (max-width : 700px) {
        @content;
    }
}

@mixin small {
    @media only screen and (min-width : 701px) and (max-width : 980px) {
        @content;
    }
}

@mixin small_down {
    @media only screen and (max-width : 980px) {
        @content;
    }
}

@mixin normal {
    @media only screen and (min-width : 981px) and (max-width : 1220px) {
        @content;
    }
}

@mixin normal_header {
    @media only screen and (max-width : 1470px) {
        @content;
    }
}

@mixin normal_down {
    @media only screen and (max-width : 1220px) {
        @content;
    }
}

@mixin large {
    @media only screen and (min-width : 1221px) and (max-width : 1500px) {
        @content;
    }
}

@mixin small_big {
    @media only screen and (min-width : 1221px) and (max-width : 1800px) {
        @content;
    }
}

@mixin big {
    @media only screen and (min-width : 1501px) {
        @content;
    }
}

@mixin link-hover () {
    opacity: 0.7;
    transition: 0.3s;
}


//FONTRESPONSIVA

$vp_mini: 700px;
$vp_small: 980px;
$vp_normal: 1220px;
$vp_large: 1500px;
$vp_big: 1920px;

$minfont: 11;

$font-treshold: 10;

@function strip-unit($number) {
    @if type-of($number) == 'number' and not unitless($number) {
        @return $number / ($number * 0 + 1);
    }

    @return $number;
}

@function calcfont($fontores, $viewport) {
    $fontenc: $fontores + $font-treshold;
    $fontout: round(strip-unit($fontenc) * (strip-unit($viewport) /strip-unit($vp_big)));

    @if($fontout < $fontores) {
        @return $fontout;
    }
    @else {
        @return $fontores;
    }
}

@function minfont($fontin, $fontminima: 0) {
    @if $fontminima != 0 {
        @if $fontin > $fontminima {
            @return $fontin;
        }
        @else {
            @return $fontminima;
        }
    }
    @else {
        @if $fontin > $minfont {
            @return $fontin;
        }
        @else {
            @return $minfont;
        }
    }
}


@mixin fontresponsiva($tamanho: 0, $lineheight: 0, $fontweight: '',$familia: '',$letterspace: 0, $fontminima: 0) {

    $bigfont: minfont($tamanho);

    @if $lineheight != 0 {
        $lineheight: percentage($lineheight/$bigfont);
        line-height: round($lineheight);
    }

    @if $letterspace != 0 {
        $letterspace: $letterspace/$bigfont;
        letter-spacing: $letterspace+em;
    }

    @media only screen and (max-width : $vp_mini) {
        $outfont: calcfont($tamanho, $vp_mini);
        $qfont: minfont($outfont, $fontminima);
        @include font($qfont+px, '', $fontweight, $familia);
    }

    @media only screen and (min-width : $vp_mini + 1 ) and (max-width : $vp_small) {
        $outfont: calcfont($tamanho, $vp_small);
        $qfont: minfont($outfont, $fontminima);
        @include font($qfont+px, '', $fontweight, $familia);
    }

    @media only screen and (min-width: $vp_small + 1) and (max-width : $vp_normal) {
        $outfont: calcfont($tamanho, $vp_normal);
        $qfont: minfont($outfont, $fontminima);
        @include font($qfont+px, '', $fontweight, $familia);
    }

    @media only screen and (min-width : $vp_normal + 1 ) and (max-width : $vp_large) {
        $outfont: calcfont($tamanho, $vp_large);
        $qfont: minfont($outfont, $fontminima);
        @include font($qfont+px, '', $fontweight, $familia);
    }

    @media only screen and (min-width : $vp_large + 1) {
        @include font($bigfont+px, '', $fontweight, $familia);
    }
}

@mixin get-line-height($line-height, $font-size) {
    line-height: percentage($line-height/$font-size);
}

@mixin font($tamanho: 0, $cor: '',$fontweight: '',$familia: '') { //@include font(12px,#666,300);
    @if $familia != '' {
        font-family: $familia;
    }

    @if $cor != '' {
        color: $cor;
    }

    @if $fontweight != '' {
        font-weight: $fontweight;
    }

    @if $tamanho != 0 {
        font-size: $tamanho;
    }
}
