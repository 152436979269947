@import "../../assets/dev/css/_config.scss";

.ConteudoVideo {
    &__wrapper {
        height: 980px;
        overflow: hidden;

        @include small_big {
            height: 790px;
        }

        @include normal {
            height: 790px;
        }

        @include normal {
            height: 700px;
        }

        @include small {
            height: 590px;
        }

        @include mini {
            height: 360px;
        }

        .youtube-container {
            width: 100%;
            height: 100%;
        }

        video, iframe {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
}