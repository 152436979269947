@import "../../assets/dev/css/_config.scss";

.ContactosUteis {
    padding-top: 80px;
    padding-bottom: 108px;

    @include mini {
        padding-top: 50px;
        padding-bottom: 65px;
    }

    &__wrapper {
        .titulos {
            display: block;
            margin-bottom: 32px;

            .titulo {
                display: block;
            }
        }

        .listagem {
            display: grid;
            grid-auto-columns: 1fr;
            grid-auto-rows: 1fr;
            grid-template-columns: repeat(3, 1fr);
            gap: 20px;

            @include small {
                grid-template-columns: repeat(2, 1fr);
            }

            @include mini {
                grid-template-columns: repeat(1, 1fr);
            }

            .bloco {
                width: 100%;
                background-color: var(--color-alternativa);

                .bloco_wrapper {
                    display: flex;
                    flex-flow: column;
                    padding: 40px;
                    box-sizing: border-box;

                    &__titulo {
                        display: block;
                        margin-bottom: 12px;
                    }

                    &__morada {
                        display: block;
                        margin: 12px 0;
                        line-height: 150%;
                    }

                    &__contactos {
                        display: block;
                        margin: 12px 0;

                        .contacto {
                            display: block;
                            line-height: 150%;
                        }
                    }
                }
            }
        }
    }
}
