@import "../_config.scss";

.main-btn {
    padding: 15px 50px;
    border-radius: 37.5px;
    background-color: var(--main-btn-background);
    color: var(--main-btn-color);
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    text-align: center;
    font-family: var(--main-font-family);
    cursor: pointer;
    transition: all 300ms ease-in-out;

    &:hover {
        opacity: 0.7;
    }
}

.btn-reservar {
    border-radius: 37.5px;
    background-color: #ECC118;
    width: 90%;
    max-width: 360px;
    padding: 20px;
    box-sizing: border-box;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    text-align: center;
    cursor: pointer;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 40px;
    position: fixed;
    transition: all 350ms ease-in-out;
    display: none;
    z-index: 10;

    @include mini {
        display: block;
    }
}

.comp-main-button {
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 7px 30px;
    width: max-content;
    min-width: 162px;
    min-height: 40px;
    font-family: var(--main-font-family);
    line-height: 20px;
    letter-spacing: 0;
    text-align: center;
    cursor: pointer;
    @include font(14px, var(--color-btn-texto), bold);
    border: 1px solid var(--main-btn-background);
    background-color: var(--main-btn-background);
    color: var(--main-btn-color);
    border-radius: 37.5px;
    transition: all 300ms ease-in-out;
    opacity: 1;

    &:hover {
        opacity: 0.7;
        transition: all 300ms ease-in-out;
    }
}

.comp-main-button .icon-ma {
    font-size: 20px;
    margin-left: 10px;
    vertical-align: middle;
    display: flex;
}

.comp-main-button.invert {
    color: white;
    background-color: var(--brand-color);
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
    padding: 14px 65px;
    font-family: var(--secundary-font);
}
/*.comp-main-button:hover {
    background-color: var(--color-btn-backg-hover);
    color: var(--color-btn-texto-hover);
    transition: all 300ms ease-in-out;
}*/


// fundo cinza
.comp-main-button.alt {
    border: 2px solid var(--main-btn-background);
    background-color: transparent;
    color: var(--main-btn-background);
    font-weight: bold;
}

/*.comp-main-button.alt:hover {
    color: var(--color-btn-texto-hover-secundario);
    background-color: var(--color-btn-backg-hover-secundario);
}*/

.comp-main-button.principal {
    min-height: 60px;
    border: 0;
    color: var(--color-btn-texto-principal);
    background-color: var(--color-btn-backg-principal);
}

.comp-main-button.principal:hover {
    background-color: var(--color-btn-backg-hover-principal);
    color: var(--color-btn-texto-hover-principal);
}

.comp-formularios .comp-main-button.principal:hover {
    background-color: var(--color-btn-backg-hover-principal);
    color: var(--color-btn-texto-principal);
}

.comp-main-button.button-lines {
    display: inline-block;
    font-size: 12px;
    font-weight: normal;
    float: right;
    height: 45px;
    letter-spacing: 1px;
    border: 1px solid var(--color-default);
    background-color: transparent;
    color: var(--color-default);
    line-height: 45px;
}

.comp-main-button.button-lines:hover {
    background-color: var(--color-default);
    color: $color-white;
}

.comp-main-button-card {
    box-sizing: border-box;
    display: inline-block;
    padding: 0 20px;
    color: var(--color-btn-texto-secundario);
    font-family: var(--alt-font-family);
    font-size: 14px !important;
    font-weight: bold;
    cursor: pointer;
    background-color: var(--color-btn-backg-secundario);
    height: 50px;
    line-height: 45px;
    text-align: center;
    border: 2px solid var(--color-btn-border-secundario);
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    border-radius: 4px;
}

.comp-main-button-card:hover {
    color: var(--color-btn-texto-hover-secundario);
    background-color: var(--color-btn-backg-hover-secundario);
    transition: all 300ms ease-in-out;
}

.comp-main-button-card .icon-ma {
    color: var(--color-btn-texto-secundario);
    margin-right: 7px;
    font-size: 20px;
    top: 3px;
}

.comp-main-button-card:hover .icon-ma {
    color: var(--color-btn-texto);
}
