@import "../_config.scss";

/*CONFIG*/
$base: $text-color;
$principal: $color-base;
$colors-list: $title-color $text-color;

/*----CONFIG*/

.stylespage {
    font-size: 0;
}

/*BANNERS*/

/*FONTS*/
.ma-normal-60 {
    @include fontresponsiva(60, 60, 400, var(--main-font-family), 0, 30);
}

.ma-normal-48 {
    @include fontresponsiva(48, 48, 400, var(--main-font-family), 0, 24);
}

.ma-normal-32 {
    @include fontresponsiva(32, 32, 400, var(--main-font-family), 0, 24);
}

.ma-normal-28 {
    @include fontresponsiva(28, 28, 500, var(--main-font-family), 0, 24);
}

.ma-normal-24 {
    @include fontresponsiva(24, 24, 400, var(--main-font-family), 0, 18);
}

.ma-normal-20 {
    @include fontresponsiva(20, 20, 400, var(--main-font-family), 0, 16);
}

.ma-normal-18 {
    @include fontresponsiva(18, 20, 400, var(--main-font-family), 0, 18);
}

.ma-normal-16 {
    @include fontresponsiva(16, 20, 400, var(--main-font-family), 0, 16);
}

.ma-normal-14 {
    @include fontresponsiva(14, 21, 400, var(--main-font-family), 0, 14);
}

.ma-normal-12 {
    @include fontresponsiva(12, 18, 400, var(--main-font-family), 0, 12);
}

.ma-bold-16 {
    @include fontresponsiva(16, 20, 600, var(--main-font-family), 0, 16);
}
.ma-bold-18 {
    @include fontresponsiva(18, 20, 600, var(--main-font-family), 0, 16);
}

.ma-secundary-normal-56 {
    @include fontresponsiva(56, 56, 300, var(--alt-font-family), 0, 48);
}

.ma-menu {
    @include fontresponsiva(16, 20, 600, var(--main-font-family), 0, 12);

    @include small_down {
        font-size: 22px;
        line-height: 34px;
    }
}

.ma-menu-footer {
    @include fontresponsiva(14, 23, 400, var(--main-font-family), 0, 12);
    opacity: 0.7;
    transition: opacity 200ms ease-in-out;
}

/*CORES*/
.ma-fcor-principal {
    color: $principal;
}

@each $current-color in $colors-list {
    $i: index($colors-list, $current-color);

    .ma-fcor-0#{$i} {
        color: $current-color;
    }
}
/*----CORES*/
/*STYLEPAGE CONFIG*/
.stylespage .wrapper {
    width: calc(100%-8px);
    padding: 50px;
    box-sizing: border-box;
    background-color: #eee;
    border: 1px solid #111;
    margin: 4px;
}

.stylespage .wrapper h4 {
    margin-bottom: 30px;
    font-size: 15px;
    font-weight: bold;
    color: #000;
}

.stylespage .wrapper div {
    height: 60px;
    border: 1px solid red;
}

.stylespage .wrapper > span {
    display: block;
    clear: both;
    margin-bottom: 20px;
    user-select: none;
}

.stylespage .wrapper > span p,
.stylespage .wrapper > span::before {
    display: block;
    clear: both;
    font-weight: bold;
    margin: 4px;
    font-size: 15px;
    color: black
}

.stylespage .wrapper.cores {
    position: fixed;
    top: 0;
    right: 0;
    max-width: 500px;
}

.stylespage .wrapper:not(.cores) > span {
    width: calc(100% - 500px);
}

.stylespage .wrapper.cores > span {
    display: inline-block;
    width: 50%;
    font-size: 15px;
    box-sizing: border-box;
    padding: 5px;
}

.stylespage .spacer {
    width: 40px;
    display: inline-block;
}

.stylespage .copyButton {
    margin-left: 15px;
}
/*----STYLEPAGE CONFIG*/

