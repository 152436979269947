@import "../../assets/dev/css/_config.scss";

.RoteiroDetalhe {
    .btn-direccoes {
        width: 280px;
        position: absolute;
        z-index: 1;
        left: 0;
        background-color: white;
        box-shadow: 0px 12px 24px -10px rgba(0, 0, 0, 0.05);
        bottom: 40px;
        right: 0;
        text-align: center;
        margin: 0 auto;
        padding: 16px 32px;
        align-items: center;
        gap: 12px;
        color: var(--color-principal);
        text-transform: uppercase;
        cursor: pointer;
        transition: all 200ms ease-in-out;
        width: 280px;
        padding-right: 80px;

        maicontaghelper, svg {
            width: 30px;
            height: 25px;
            margin: 0;
            position: absolute;
            right: 15px;
            transition: all 200ms ease-in-out;
        }

        &:hover {
            transition: all 200ms ease-in-out;
            font-weight: 600;

            maicontaghelper, svg {
                width: 35px;
                height: 26px;
                transition: all 200ms ease-in-out;
            }
        }
    }

    .partilha-holder {
        padding-top: 24px;

        &:before {
            content: '';
            display: block;
            background: #D9D9D9;
            height: 1px;
            width: 100%;
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            top: 0;
        }

        &.semconteudo {
            &:before {
                display: none;
            }
        }

        .social_shares a, .social_shares div {
            background: #FFF;
        }
    }

    .mapa-holder {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        height: 610px;
        z-index: 1;

        @include mini {
            height: 350px;
        }

        @include small_down {
            height: 500px;
        }


        .OpenStreetMaps {
            display: block;
            height: 100%;
            width: 100%;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .btn-direccoes {
        width: 280px;
        position: absolute;
        z-index: 1;
        left: 0;
        background-color: white;
        box-shadow: 0px 12px 24px -10px rgba(0, 0, 0, 0.05);
        bottom: 40px;
        right: 0;
        text-align: center;
        margin: 0 auto;
        padding: 16px 32px;
        align-items: center;
        gap: 12px;
        color: var(--color-principal);
        text-transform: uppercase;
        cursor: pointer;
        transition: all 200ms ease-in-out;
        width: 280px;
        padding-right: 80px;

        maicontaghelper, svg {
            width: 30px;
            height: 25px;
            margin: 0;
            position: absolute;
            right: 15px;
            transition: all 200ms ease-in-out;
        }

        &:hover {
            transition: all 200ms ease-in-out;
            font-weight: 600;

            maicontaghelper, svg {
                width: 35px;
                height: 26px;
                transition: all 200ms ease-in-out;
            }
        }
    }

    .banner-principal {
        padding-top: 25%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        padding-bottom: 55px;

        &:before {
            content: "";
            width: 100%;
            height: 30%;
            position: absolute;
            bottom: 0;
            left: 0;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.70) 78.04%);
        }

        .titulos {
            z-index: 1;
            display: flex;
            align-items: flex-end;
            justify-content: flex-start;
            gap: 18px;
            color: #fff;

            .titulo__info {
                min-height: 120px;
            }

            .breadcrumb {
                margin-bottom: 8px;
                display: flex;
                align-items: center;
                gap: 5px;
                color: #bdbcbc;

                a {
                    display: flex;
                    align-items: center;
                    color: #bdbcbc !important;
                    text-decoration: underline;
                    gap: 3px;

                    .icon-ma {
                        width: 12px;
                        transform: rotate(180deg);
                    }
                }
            }

            .icon {
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                background-color: var(--color-principal);
                height: 88px;
                width: 88px;
                align-self: center;
                opacity: 0;
                pointer-events: none;

                img {
                    height: 100%;
                    width: 100%;
                    max-height: 48px;
                    max-width: 48px;
                    object-fit: contain;
                }

                img {
                    height: 48px;
                }

                &.mobile {
                    display: none;
                }

                @include mini {
                    display: none;

                    &.mobile {
                        display: flex;
                        width: 88px;
                        box-sizing: border-box;
                        margin-left: 20px;
                    }
                }
            }

            .page-title {
                line-height: 120%;

                @include mini {
                    display: block;
                    width: calc(100% - 20px);
                    margin: 0 auto;
                    margin-left: 20px;
                }
            }

            .localizacao {
                color: var(--color-extra);
                line-height: 130%;

                @include mini {
                    display: block;
                    width: calc(100% - 20px);
                    margin: 0 auto;
                    margin-left: 20px;
                }
            }

            .tags {
                display: flex;
                flex-flow: wrap;
                gap: 16px;
                padding-top: 15px;
                padding-bottom: 0;

                .tag-item {
                    display: flex;
                    padding: 10px 16px;
                    align-items: flex-start;
                    gap: 10px;
                    border-radius: 200px;
                    background: #FFF;
                    box-shadow: 0px 12px 24px -10px rgba(0, 0, 0, 0.04);
                    font-size: 14px;
                    line-height: 150%;
                    font-weight: 600;
                    color: var( --color-principal);
                    border: 1px solid var( --color-principal);
                }
            }
        }
    }

    .info-block {
        display: flex;

        @include small_down {
            flex-direction: column;

            > div {
                width: 100%;
                padding: 0 5%;
            }
        }

        &__left {
            width: 68%;
            padding-left: 4%;
            padding-bottom: 80px;
            box-sizing: border-box;

            .bloco-texto {
                width: calc(85% - 200px);
                padding: 45px 0;
                padding-right: 5%;
                max-width: 720px;
                margin: 0 auto;
                line-height: 130%;
                margin-left: 110px;

                @include small_down {
                    width: 90%;
                    margin: 0 auto;
                }
            }

            .bloco-texto-bottom {
                line-height: 150%;
            }

            .galeria {
                overflow: hidden;

                @include mini {
                    width: 95%;
                    overflow: visible;
                }

                .swiper-slide {
                    padding-bottom: 480px / 920px * 100%;

                    @include mini {
                        padding-bottom: 100%;
                    }
                }

                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                .swiper-navigation {
                    display: flex;
                    justify-content: flex-end;

                    @include mini {
                        display: none;
                    }

                    maicontaghelper,
                    .icon-ma {
                        width: 24px;
                        height: 24px;
                    }
                }
            }
        }

        &__right {
            width: 32%;
            box-sizing: border-box;
            padding: 45px 6% 4% 4%;
            background-color: var(--color-alternativa);

            &.fixed {
                .info-block-right {
                    position: fixed;
                    top: 110px;
                }
            }


            @include small_down {
                padding-top: 45px !important;
                padding-bottom: 45px !important;
            }

            .extra-info {
                display: flex;
                gap: 8px;
                margin-bottom: 24px;

                > div {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                }
            }
        }
    }
}
