@import "../../assets/dev/css/_config.scss";

.ConteudoGaleria {
    padding: 4% 0px;

    .swiper-container {
        height: 730px;
        overflow: hidden;

        @include large {
            height: 530px;
        }

        @include normal {
            height: 530px;
        }

        @include small_down {
            height: 410px;
        }

        @include mini {
            height: 310px;
        }

        img {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            object-fit: cover;
        }

        .video {
            position: absolute;
            border-radius: 24px;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            background-color: var(--color-alternativa);
        }

        .play-btn {
            display: flex;
            width: 80px;
            height: 80px;
            padding: 30px 30px 30px 50px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            margin: auto;
            z-index: 9;
            cursor: pointer;
            position: absolute;
            background: var(--color-default);
            box-sizing: border-box;
            border-radius: 100px;

            &:after {
                content: '';
                display: block;
                width: 34px;
                height: 28px;
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                margin: auto;
                background-size: contain;
                top: 0;
                bottom: 0;
                right: 0;
                left: 3px;
                margin: auto;
                background-position: center;
                background-repeat: no-repeat;
                background-image: url('/dist/images/play.svg');
            }
        }
    }

    .navigation {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .swiper-button-prev {
            margin: 0;
        }

        .swiper-button-next {
            margin: 0;
        }

        svg, maicontaghelper {
            width: 30px;

            &:hover {
                width: 34px;
            }
        }
    }
}
