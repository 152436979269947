@import "../../assets/dev/css/_config.scss";

$urltransition: 300ms ease-in-out;


.HomepagePatrimonio {
    overflow: hidden;
    background-color: var(--color-alternativa);
    padding-bottom: 70px;

    &__titulo {
        display: block;
        padding-top: 42px;
        padding-bottom: 24px;
    }

    &__lista {
        display: flex;
        gap: 2%;
        flex-wrap: wrap;

        @include small_down {
            flex-direction: column;
        }

        .PatrimonioItem {
            width: 48%;
            padding: 60px 40px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            gap: 24px;
            cursor: pointer;
            margin-bottom: 16px;
            background-color: white;
            overflow: hidden;
            border-radius: 20px;
            height: 210px;


            @include small_down {
                width: 100%;
                padding: 40px;
                height: 171px;
            }

            &__icone {
                overflow: hidden;
                max-width: 90px;
                min-width: 90px;
                width: 100%;
                opacity: 1;
                transition: 200ms opacity ease-in-out;

                .icone-holder {
                    width: 88px;
                    height: 88px;
                    border-radius: 50%;
                    padding: 20px;
                    box-sizing: border-box;
                    background-color: var(--color-principal);

                    img {
                        height: 100%;
                    }
                }
            }


            &__info-holder {
                display: flex;
                flex-direction: column;
                transition: all 200ms ease-in-out;
                width: auto;

                .nome {
                    color: var(--color-principal);

                    @include small_down {
                        font-size: 20px;
                    }
                }

                .resumo {
                    transition: opacity 0ms ease-out;
                    opacity: 0;
                    height: 0;
                }
            }

            &__link {
                margin-left: auto;
                z-index: 9;

                maicontaghelper, svg {
                    width: 30px;
                    height: 25px;
                    display: flex;
                    transition: $urltransition;
                }
            }

            &__hover {
                z-index: 1;
                position: absolute;
                border-radius: 50%;
                overflow: hidden;
                width: 400px;
                height: 400px;
                right: -400px;
                top: -400px;

                img {
                    height: 100%;
                    width: 100%;
                }
            }

            &:hover {

                .PatrimonioItem__hover {
                    transition: all 400ms ease-in-out;
                    right: -120px;
                    top: -160px;

                    @include normal {
                        right: -200px;
                        top: -160px;
                    }

                    @include mini {
                        right: -230px;
                        top: -160px;
                    }
                }

                .PatrimonioItem__link {
                    maicontaghelper, svg {
                        width: 35px;
                        transition: $urltransition;
                    }
                }

                .PatrimonioItem__icone {
                    max-width: 0;
                    opacity: 0;
                    position: absolute;
                    transition: 200ms opacity ease-in-out;
                }

                .PatrimonioItem__info-holder {
                    width: 50%;
                    transition: all 600ms ease-in-out;


                    .resumo {
                        transition: opacity 200ms ease-in;
                        opacity: 1;
                        padding-top: 8px;
                        height: auto;
                    }
                }
            }
        }
    }
}