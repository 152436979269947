@import "../../assets/dev/css/_config.scss";

.Pagina404 {
    padding: 250px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
        margin-bottom: 40px;
    }

    h1 {
        max-width: 400px;
        margin: 0 auto 50px;
    }

    p {
        max-width: 400px;
        margin: 0 auto;
    }

    @include small_down {
        padding: 150px 0;
    }
}