@import "../../assets/dev/css/_config.scss";

.Header {
    padding: 10px 0;
    box-sizing: border-box;
    background: white;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    z-index: 14;
    transition: all 400ms ease-in-out;

    &.header-up {
        top: -100%;
    }

    &.isOpen {
        position: fixed;
        max-height: 100vh;
        overflow-y: auto;
    }

    .btn-pesquisa {
        font-size: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 20px;
        height: 20px;
        padding: 0;

        @include normal_header {
            position: absolute;
            right: 40px;
        }

        .holder-pesquisa {
            position: absolute;
        }

        .icon-ma {
            display: flex;
            max-height: 31px;
        }

        .fechar {
            display: none;
            width: 28px;
            font-family: var(--secundary-font);
        }

        &.open {

            .pesquisa {
                display: none;
            }

            .fechar {
                display: flex;
                justify-content: center;
                align-items: center;
                color: white !important;
            }
        }

        @include small_down {
            padding-right: 10px;
            padding-left: 0;
        }
    }

    .search-block {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding: 150px 0 20px;
        background-color: white;
        transform: translateY(-100%);
        transition: all 400ms ease-in-out;

        @include small_down {
            padding: 120px 0 20px;
        }

        &.open {
            transform: translateY(0);
        }

        &__input-holder {
            width: 90%;
            max-width: 1108px;
            margin: 0 auto;
            padding-bottom: 40px;
            border-bottom: 1px solid #DCDCDC;

            .btn-submit-search {
                font-size: 40px;
                position: absolute;
                right: 0;
                top: 20px;
                color: white;
                cursor: pointer;
                z-index: 1;

                .icon-ma {
                    width: 50px;
                    height: 50px;

                    @include small_down {
                        width: 25px;
                        height: 25px;
                    }
                }

                @include small_down {
                    top: 8px;
                    font-size: 30px;
                }
            }

            @include small_down {
                padding-bottom: 20px;
            }
        }

        &__input {
            width: 100%;
            border: none;
            background-color: transparent;
            font-size: 40px;
            line-height: 40px;

            &::placeholder {
                font-size: 40px;
                line-height: 40px;
                color: rgba(0,0,0,0.5);
            }

            @include small_down {
                font-size: 30px;
                line-height: 30px;

                &::placeholder {
                    font-size: 30px;
                    line-height: 30px;
                    color: rgba(0,0,0,0.5);
                }
            }
        }

        &__results {
            padding: 0 0 50px;
            width: 90%;
            max-width: 1108px;
            margin: 0 auto;
            max-height: 390px;
            overflow-y: auto;
            margin-top: 40px;
            /* width */
            &::-webkit-scrollbar {
                width: 5px;
            }
            /* Track */
            &::-webkit-scrollbar-track {
                background: #f1f1f1;
            }
            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: var(--color-principal);
            }
            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
                background: #555;
            }

            .results__label {
                display: none;
                align-items: center;
                margin-bottom: 40px;

                .count {
                    margin-right: 3px;
                }
            }

            &.done {
                display: block;

                .results__label {
                    display: flex;
                }
            }

            .lds {
                display: none;
            }

            &.waiting {

                .results__label {
                    display: none;
                }

                .lds {
                    display: block;
                    position: relative;
                    width: 80px;
                    height: 80px;
                    margin: 0 auto;
                }

                .lds div {
                    display: inline-block;
                    position: absolute;
                    left: 8px;
                    width: 16px;
                    background: #fff;
                    animation: lds 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
                }

                .lds div:nth-child(1) {
                    left: 8px;
                    animation-delay: -0.24s;
                }

                .lds div:nth-child(2) {
                    left: 32px;
                    animation-delay: -0.12s;
                }

                .lds div:nth-child(3) {
                    left: 56px;
                    animation-delay: 0;
                }

                @keyframes lds {
                    0% {
                        top: 8px;
                        height: 64px;
                    }

                    50%, 100% {
                        top: 24px;
                        height: 32px;
                    }
                }
            }

            .results__item {
                display: flex;
                align-items: center;
                background: white;
                margin-bottom: 10px;

                .link {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                }

                .image {
                    width: 128px;
                    height: 128px;
                    display: flex;
                    box-sizing: border-box;
                    justify-content: center;
                    align-items: center;
                    padding: 20px;
                    background: white;

                    > div {
                        overflow: hidden;
                        height: 100%;

                        img {
                            height: 100%;
                        }
                    }

                    @include small_down {
                        width: 90px;
                        height: 90px;
                        min-width: 90px;
                        padding: 15px;
                    }
                }

                .info {
                    padding: 0 80px 0 40px;

                    h2 {
                        margin-bottom: 7px;
                        font-size: 24px;
                        line-height: 24px;

                        @include small_down {
                            font-size: 20px;
                            line-height: 20px;
                        }
                    }

                    h3 {
                        color: var(--color-principal);
                        font-size: 14px;
                        text-transform: uppercase;
                    }

                    @include small_down {
                        padding: 0 50px 0 10px;
                    }
                }

                .btn-url {
                    position: absolute;
                    right: 40px;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    width: 20px;
                    height: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: white;

                    &:after {
                        content: '';
                        display: block;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        right: 0;
                        left: 0;
                        margin: auto;
                        background-size: contain;
                        top: 0;
                        bottom: 0;
                        right: 0;
                        margin: auto;
                        background-position: center;
                        background-repeat: no-repeat;
                        background-image: url('/dist/images/seta_green.svg');
                    }

                    @include small_down {
                        right: 20px;
                    }
                }
            }
        }
    }

    &__inner {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .logo {
            z-index: 7;
        }

        .navigation {
            display: flex;
            align-items: center;
            gap: 32px;
            transition: all 300ms ease-in-out;

            @include normal_header {
                position: fixed;
                top: 0;
                right: -100%;
                height: 100%;
                overflow: auto;
                flex-direction: column;
                width: 100%;
                max-width: 400px;
                background: white;
                z-index: 8;
                padding: 141px 5% 5%;
                box-sizing: border-box;

                &.open {
                    right: 0;
                }
            }

            &__menu {
                display: flex;
                align-items: center;
                gap: 22px;

                @include large {
                    gap: 12px;
                }

                @include normal_header {
                    width: 100%;
                    flex-direction: column;
                    align-items: flex-start;
                }

                .menu-link {
                    padding: 0 12px;

                    @include normal_header {
                        padding: 0;
                    }

                    .page-link {
                        cursor: pointer;
                        text-transform: uppercase;
                        display: flex;
                        align-items: center;
                        gap: 18px;
                        color: var(--color-secundaria);
                        color: var(--fills-grey, #333734);
                        font-family: Archivo;
                        font-size: 14px;

                        @include normal_header {
                            font-size: 24px;
                        }

                        img {
                            padding: 20px;
                            background: var(--color-principal);
                            border-radius: 50%;
                            transition: all 300ms ease-in-out;
                            max-height: 40px;
                        }

                        &:hover {

                            img {
                                transform: scale(1.03);
                            }

                            .icon-ma {
                                width: 25px;
                            }
                        }


                        > span {
                            display: flex;
                            flex-direction: column;
                            text-transform: none;

                            span {
                                display: flex;
                                text-transform: none;
                                align-items: center;
                                gap: 12px;
                            }
                        }

                        .link {
                            .nome {
                                line-height: 130%;
                            }

                            .desc {
                                line-height: 150%;
                                font-size: 14px;
                            }
                        }
                    }

                    .navigation__submenu {
                        opacity: 0;
                        pointer-events: none;
                        position: absolute;
                        top: 200%;
                        left: -48px;
                        width: 720px;
                        padding: 48px;
                        border-radius: 20px;
                        border: 1px solid rgba(0, 0, 0, 0.05);
                        background: #FFF;
                        box-shadow: 0px 12px 24px -10px rgba(0, 0, 0, 0.05);
                        z-index: 4;
                        display: flex;
                        flex-wrap: wrap;
                        gap: 32px;
                        transition: all 300ms ease-in-out;

                        @include normal_header {
                            padding: 48px 0 0 48px;
                            height: 100%;
                        }

                        .voltar {
                            display: none;
                        }

                        .page-link {
                            width: calc(50% - 16px);
                        }

                        .wrapper-menu {
                            display: flex;
                            flex-flow: wrap;
                            gap: 32px;
                        }

                        @include normal_header {
                            position: fixed;
                            top: 0;
                            right: -100%;
                            left: auto;
                            width: 100%;
                            max-width: 400px;
                            box-sizing: border-box;
                            flex-direction: column;
                            /*padding: 140px 5%;*/
                            background: white;
                            height: 100%;
                            flex-flow: column;

                            .wrapper-menu {
                                overflow-y: auto;
                                display: flex;
                                flex-flow: column;
                                height: 100%;
                                padding-right: 20px;
                                gap: 0;
                            }

                            .page-link {
                                width: 100%;
                                margin-bottom: 30px;
                            }

                            .page-link span:first-child {
                                font-size: 20px;
                            }

                            .voltar {
                                display: flex;
                                align-items: center;
                                margin-bottom: 30px;
                                gap: 18px;

                                .icon-ma {
                                    transform: rotate(180deg);
                                }

                                span {
                                    text-transform: uppercase;
                                    font-size: 24px
                                }
                            }
                        }
                    }

                    &.open {

                        .navigation__submenu {
                            opacity: 1;
                            pointer-events: all;

                            @include normal_header {
                                right: 0;
                            }
                        }
                    }
                }
            }

            @include normal_header {
                .idiomas {
                    width: 100%;
                }
            }

            .idioma {
                padding: 11px 10px;
                display: block;
                color: var(--color-principal);
                border: 2px solid var(--color-principal);
                border-radius: 50%;
                font-size: 14px;
                text-transform: uppercase;
                font-weight: 500;
                cursor: pointer;

                @include normal_header {
                    display: none;
                }
            }

            .idiomas__drop {
                position: absolute;
                opacity: 0;
                pointer-events: none;
                top: 110%;
                z-index: 2;
                display: flex;
                flex-direction: column;
                gap: 5px;
                transition: all 300ms ease-in-out;

                li .active {
                    display: none;
                }

                &.open {
                    top: 120%;
                    opacity: 1;
                    pointer-events: all;
                }

                @include normal_header {
                    position: relative;
                    flex-direction: row;
                    opacity: 1;
                    pointer-events: all;
                    z-index: 1;
                    top: 0;
                    padding-top: 15%;
                    justify-content: flex-start;
                }

                a {
                    padding: 12px 10px;
                    display: block;
                    color: var(--color-principal);
                    border: 2px solid white;
                    border-radius: 50%;
                    font-size: 14px;
                    text-transform: uppercase;
                    font-weight: 500;
                    background: white;

                    &.active {
                        border: 2px solid var(--color-principal);
                    }
                }
            }
        }

        .ham-menu {
            width: 22px;
            height: 22px;
            display: none;

            @include normal_header {
                display: block;
                z-index: 16;
            }

            span {
                width: 100%;
                height: 3px;
                border-radius: 3px;
                background: #000;
                position: absolute;
                top: 5px;
                transition: all 300ms ease-in-out;

                &:last-of-type {
                    top: 15px;
                }
            }

            &.open {

                span {
                    transform: rotate(45deg);
                    top: 10px;

                    &:last-of-type {
                        transform: rotate(-45deg);
                        top: 10px;
                    }
                }
            }
        }
    }
}
