@import "../../assets/dev/css/_config.scss";

.InfoNoticia {
    padding: 70px 0;

    .wrapper {
        gap: 50px;
        display: flex;
        flex-flow: wrap;

        @include small_down {
            flex-flow: column;
        }
    }

    .textos-holder {
        width: calc(70% - 50px);

        @include small_down {
            width: 100%;
        }
    }

    .textos {
        max-width: 850px;
        width: 90%;
        display: flex;
        flex-direction: column;
        gap: 24px;
        box-sizing: border-box;
    }

    .partilha-holder {
        width: 30%;

        @include small_down {
            width: 100%;
        }
    }

    .desc {
        line-height: 150%;
    }

    .texto {
        line-height: 150%;
    }

    .galeria {
        overflow: hidden;
        padding-top: 45px;

        @include mini {
            width: 95%;
            overflow: visible;
        }

        .swiper-slide {
            padding-bottom: 480px / 920px * 100%;

            @include mini {
                padding-bottom: 100%;
            }
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .swiper-navigation {
            display: flex;
            justify-content: flex-end;

            @include mini {
                display: none;
            }

            maicontaghelper,
            .icon-ma {
                width: 24px;
                height: 24px;
            }
        }
    }
}
