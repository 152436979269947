@import "../../assets/dev/css/_config.scss";

.ContactosMapa {
    padding-top: 95px;

    &__wrapper {

        .topo {
            display: flex;
            align-items: stretch;

            @include small_down {
                flex-direction: column;
            }

            .left {
                width: 44%;

                @include small_down {
                    height: 270px;
                    width: 100%;
                }

                .img-holder {
                    height: 100%;
                    width: 100%;
                    display: block;
                    overflow: hidden;

                    img {
                        width: 100%;
                        object-fit: cover;
                        height: 100%;
                    }

                    &:after {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 100%;
                        width: 100%;
                        background: linear-gradient(180deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.38) 31.15%, rgba(0, 0, 0, 0.00) 100%);
                    }
                }

                .info-holder {
                    position: absolute;
                    color: white;
                    top: 0;
                    padding-top: 70px;
                    padding-left: 10%;
                    max-width: 460px;

                    @include small_down {
                        padding-left: 9%;
                        width: 80%;
                    }

                    .titulo {
                        line-height: 120%;
                    }

                    h2 {
                        line-height: 130%;
                    }
                }
            }

            .right {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 56%;
                background-color: var(--color-alternativa);
                padding-left: 9%;
                padding-top: 5%;
                padding-right: 5%;
                padding-bottom: 5%;
                box-sizing: border-box;

                @include small_down {
                    width: 100%;
                }

                .morada {
                    padding-bottom: 32px;
                    line-height: 130%;
                }

                .info-item {
                    display: block;
                    padding-bottom: 32px;
                    line-height: 150%;
                    gap: 5px;

                    @include mini {
                        flex-flow: column;
                    }

                    .label {
                        display: inline-block;
                        line-height: 150%;

                        @include mini {
                            margin-bottom: 8px;
                        }
                    }
                }

                .redes {
                    display: flex;
                    gap: 16px;

                    a {
                        border-radius: 50%;
                        background: white;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 45px;
                        height: 45px;
                        box-sizing: border-box;

                        .icone {
                            opacity: 1;

                            &.hover {
                                position: absolute;
                                opacity: 0;
                            }
                        }

                        &:hover {
                            .icone {
                                opacity: 0;

                                &.hover {
                                    position: absolute;
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }
            }
        }

        .fundo {
            .mapa-holder {
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                height: 610px;
                z-index: 1;

                @include mini {
                    height: 350px;
                }

                @include small_down {
                    height: 500px;
                }


                .OpenStreetMaps {
                    display: block;
                    height: 100%;
                    width: 100%;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }

            .btn-direccoes {
                width: 280px;
                position: absolute;
                z-index: 1;
                left: 0;
                background-color: white;
                box-shadow: 0px 12px 24px -10px rgba(0, 0, 0, 0.05);
                bottom: 40px;
                right: 0;
                text-align: center;
                margin: 0 auto;
                padding: 16px 32px;
                align-items: center;
                gap: 12px;
                color: var(--color-principal);
                text-transform: uppercase;
                cursor: pointer;
                transition: all 200ms ease-in-out;
                width: 280px;
                padding-right: 80px;

                maicontaghelper, svg {
                    width: 30px;
                    height: 25px;
                    margin: 0;
                    position: absolute;
                    right: 15px;
                    transition: all 200ms ease-in-out;
                }

                &:hover {
                    transition: all 200ms ease-in-out;
                    font-weight: 600;

                    maicontaghelper, svg {
                        width: 35px;
                        height: 26px;
                        transition: all 200ms ease-in-out;
                    }
                }
            }
        }
    }
}
