/*======================================
  Selectric
======================================*/
.selectric-wrapper {
    position: relative;
    cursor: pointer;
    width: 100%;
    min-width: 120px;
    box-sizing: border-box;
}

.selectric-responsive {
    width: 100%;
}

.selectric {
    border: none;
    background: transparent;
    position: relative;
    border-radius: 0px;
}

    .selectric .label {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0 44px 0 16px;
        font-size: 18px;
        letter-spacing: 0;
        line-height: 28px;
        color: #101010;
        height: 28px;
        font-family: var(--secundary-font);
    }

    .selectric .button {
        background: none;
        border-radius: 0px;
        display: block;
        position: absolute;
        right: 16px;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 12px;
        height: 38px;
        color: #444444;
        text-align: center;
        font: 0/0 a;
        *font: 38px/38px Lucida Sans Unicode, Arial Unicode MS, Arial;
    }

        .selectric .button:after {
            content: " ";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            width: 0;
            height: 0;
            border: 4px solid transparent;
            border-top-color: var(--brand-color);
            border-bottom: none;
        }

.selectric-focus .selectric {
    border-color: #AAA;
}

/*.selectric-hover .selectric {
  border-color: #cfcfcf;
}*/

/*.selectric-hover .selectric .button {
  color: #cfcfcf;
}*/

/*.selectric-hover .selectric .button:after {
  border-top-color: #cfcfcf;
}*/

.selectric-open {
    z-index: 9999;
}

    .selectric-open .selectric {
        border-color: #cfcfcf;
        background: transparent;
    }

    .selectric-open .selectric-items {
        display: block;
    }

.selectric-disabled {
    filter: alpha(opacity=50);
    opacity: 0.5;
    cursor: default;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.selectric-hide-select {
    position: relative;
    overflow: hidden;
    width: 0;
    height: 0;
}

    .selectric-hide-select select {
        position: absolute;
        left: -100%;
    }

    .selectric-hide-select.selectric-is-native {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 10;
    }

        .selectric-hide-select.selectric-is-native select {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 100%;
            width: 100%;
            border: none;
            z-index: 1;
            box-sizing: border-box;
            opacity: 0;
        }

.selectric-input {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    margin: 0 !important;
    padding: 0 !important;
    width: 1px !important;
    height: 1px !important;
    outline: none !important;
    border: none !important;
    *font: 0/0 a !important;
    background: none !important;
}

.selectric-temp-show {
    position: absolute !important;
    visibility: hidden !important;
    display: block !important;
}

/* Items box */
.selectric-items {
    display: none;
    position: absolute;
    top: 120%;
    left: 0;
    padding: 20px 0;
    border-radius: 12px;
    box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.06);
    background-color: #fff;
    z-index: -1;
}

    .selectric-items .selectric-scroll {
        height: 100%;
        overflow: auto;
    }

.selectric-above .selectric-items {
    top: auto;
    bottom: 100%;
}

.selectric-items ul, .selectric-items li {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 12px;
    line-height: 20px;
    min-height: 20px;
}

.selectric-items li {
    display: block;
    padding: 8px 24px;
    border-top: 1px solid #FFF;
    border-bottom: 1px solid #EEE;
    color: #666;
    cursor: pointer;
}

    .selectric-items li.selected {
        background: #EFEFEF;
        color: #444;
    }

    .selectric-items li.highlighted {
        background: #D0D0D0;
        color: #444;
    }

    .selectric-items li:hover {
        background: #F0F0F0;
        color: #444;
    }

.selectric-items .disabled {
    filter: alpha(opacity=50);
    opacity: 0.5;
    cursor: default !important;
    background: none !important;
    color: #666 !important;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.selectric-items .selectric-group .selectric-group-label {
    font-weight: bold;
    padding-left: 10px;
    cursor: default;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background: none;
    color: #444;
}

.selectric-items .selectric-group.disabled li {
    filter: alpha(opacity=100);
    opacity: 1;
}

.selectric-items .selectric-group li {
    padding-left: 25px;
}
