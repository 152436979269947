@import "_config.scss";

article, aside, details, figcaption, figure, footer, header, hgroup, nav, section, summary {
    display: block;
}

audio, canvas, video {
    display: inline-block;
    *display: inline;
    *zoom: 1;
}

audio:not([controls]) {
    display: none;
    height: 0;
}

[hidden] {
    display: none;
}

html {
    font-size: 100%;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
}

html, button, input, select, textarea {
    font-family: var(--main-font-family);
    border: none;
    outline: none;
}

body {
    margin: 0;
}

a:focus {
    outline: thin dotted;
}

a:hover, a:active {
    outline: 0;
}

h1 {
    font-size: 1em;
    margin: 0;
}

h2 {
    font-size: 1em;
    margin: 0;
}

h3 {
    font-size: 1em;
    margin: 0;
}

h4 {
    font-size: 1em;
    margin: 0;
}

h5 {
    font-size: 1em;
    margin: 1.67em 0;
}

h6 {
    font-size: 1em;
    margin: 2.33em 0;
}

abbr[title] {
    border-bottom: 1px dotted;
}

b, strong {
    font-weight: bold;
}

blockquote {
    margin: 1em 40px;
}

dfn {
    font-style: italic;
}

mark {
    background: #ff0;
    color: #000;
}

p, pre {
    margin: 0;
}

pre, code, kbd, samp {
    font-family: monospace,serif;
    _font-family: 'courier new',monospace;
    font-size: 1em;
}

pre {
    white-space: pre;
    white-space: pre-wrap;
    word-wrap: break-word;
}

q {
    quotes: none;
}

q:before, q:after {
    content: '';
    content: none;
}

small {
    font-size: 75%;
}

sub, sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sup {
    top: -0.5em;
}

sub {
    bottom: -0.25em;
}

dl, menu, ol, ul {
    margin: 1em 0;
}

dd {
    margin: 0 0 0 40px;
}

menu, ol, ul {
    padding: 0 0 0 40px;
}

nav ul, nav ol {
    list-style: none;
    list-style-image: none;
}

img {
    border: 0;
    -ms-interpolation-mode: bicubic;
    font-size: 10px;
    color: transparent;
}

svg:not(:root) {
    overflow: hidden;
}

figure {
    margin: 0;
}

form {
    margin: 0;
}

fieldset {
    border: 1px solid #c0c0c0;
    margin: 0 2px;
    padding: .35em .625em .75em;
}

legend {
    border: 0;
    padding: 0;
    white-space: normal;
    *margin-left: -7px;
}

button, input, select, textarea {
    font-size: 100%;
    margin: 0;
    vertical-align: baseline;
    *vertical-align: middle;
    border-radius: 0;
    padding: 0;
}

button, input {
    line-height: normal;
}

button, input[type="button"], input[type="reset"], input[type="submit"] {
    cursor: pointer;
    -webkit-appearance: button;
    *overflow: visible;
}

button[disabled], input[disabled] {
    cursor: default;
}

input[type="checkbox"], input[type="radio"] {
    box-sizing: border-box;
    padding: 0;
    *height: 13px;
    *width: 13px;
}

input[type="search"] {
    -webkit-appearance: textfield;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
}

input[type="search"]::-webkit-search-decoration, input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
}

button::-moz-focus-inner, input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

textarea {
    overflow: auto;
    vertical-align: top;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

* {
    list-style: none;
    position: relative;
}

li {
    list-style: none;
}

img {
    border: 0;
}


/* ----------
GLOBAL RESETS
-------------------------- */
form {
    height: auto;
}

html, body {
    font-family: var(--main-font-family);
    margin: 0;
    padding: 0;
    border: 0;
    height: 100%;
    width: 100%;
    background-color: var(--color-background);
    color: var(--color-default);
}

h1, h2, h3, h4, h5 {
    margin: 0;
    border: 0;
    padding: 0;
    font-weight: normal;
}

.hidden {
    display: none !important;
}

a {
    color: var(--color-default);
}

a, a:hover, a:active, a:focus, a:visited {
    text-decoration: none;
    outline: none;
}

a[href^=tel] {
    text-decoration: none;
}

ul {
    margin: 0;
    padding: 0;
}

.tbl {
    display: table;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    position: relative;
    z-index: 1;
}

.tblcell {
    display: table-cell;
    vertical-align: middle;
}

input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    font-size: 14px;
    color: var(--extra-color);
}

input::-moz-placeholder { /* Firefox 19+ */
    font-size: 14px;
    color: var(--extra-color);
}

input:-ms-input-placeholder { /* IE 10+ */
    font-size: 14px;
    color: var(--extra-color);
}

input:-moz-placeholder { /* Firefox 18- */
    font-size: 14px;
    color: var(--extra-color);
}