@import "../_config.scss";

$color-loader: #000;
$bg-loader: #FFFFFF;

.comp-loading {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99999;
}

.comp-loading:before {
    content: '';
    height: 100%;
    @include opacity(0.7);
    top: 0;
    left: 0;
    margin: auto;
    position: absolute;
    width: 100%;
    background: $bg-loader;
    z-index: 1;
}

.comp-loading:after {
    content: '';
    width: 50px;
    height: 50px;
    margin: auto;
    background-color: $color-loader;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
    border-radius: 100%;
    -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
    animation: sk-scaleout 1.0s infinite ease-in-out;
}

@-webkit-keyframes sk-scaleout {
    0% {
        -webkit-transform: scale(0)
    }

    100% {
        -webkit-transform: scale(1.0);
        opacity: 0;
    }
}

@keyframes sk-scaleout {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    100% {
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
        opacity: 0;
    }
}

.loading:before {
    content: '';
    height: 100%;
    @include opacity(0.7);
    top: 0;
    left: 0;
    margin: auto;
    position: absolute;
    width: 100%;
    background: $bg-loader;
    z-index: 1;
}

.loading:after {
    content: "";
    width: 42px;
    height: 42px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -21px;
    margin-top: -21px;
    z-index: 10;
    transform-origin: 50%;
    box-sizing: border-box;
    border: 4px solid var(--brand-color);
    border-radius: 50%;
    border-top-color: transparent;
    animation: swiper-preloader-spin 1s infinite linear;
}