@import "../../assets/dev/css/_config.scss";

.GuiasListagem {
    padding: 64px 0 90px;

    .listagem {
        display: flex;
        flex-wrap: wrap;
        gap: 2%;

        .guia-card {
            width: 32%;
            padding-bottom: 32%;
            border-radius: 22px;
            box-shadow: 0px 12px 24px -10px rgba(0, 0, 0, 0.05);
            margin-bottom: 2%;
            transition: all 300ms ease-in-out;

            @include mini {
                width: 49%;
                padding-bottom: 49%;
            }

            @media only screen and (max-width : 520px) {
                width: 100%;
                padding-bottom: 100%;
            }

            &:hover {
                transform: scale(1.02);
            }

            &:before {
                content: "";
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
                border-radius: 22px;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.60) 100%);
            }

            img {
                border-radius: 22px;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            &__link {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 3;
            }

            &__info {
                position: absolute;
                padding: 0 40px 40px;
                width: 100%;
                box-sizing: border-box;
                bottom: 0;
                color: white;
                display: flex;
                flex-direction: column;
                gap: 8px;
                z-index: 2;

                .btn-descarregar {
                    display: flex;
                    align-items: center;
                    text-transform: uppercase;
                    gap: 10px;
                }
            }
        }
    }
}