@import "../_config.scss";

.comp-form {

    label.error {
        position: absolute;
        right: 20px;
        font-size: 11px;
        font-weight: 600;
        color: red;
        top: 0;
        z-index: 1;
        font-family: var(--secundary-font);
    }

    &__titles {
        padding-bottom: 65px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        .icon-ma {
            display: flex;
            margin-bottom: 28px;
            color: var(--brand-color);
        }

        h4 {
            max-width: 613px;
            margin: 0 auto;
        }
    }

    &__form {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;

        .form-row {
            width: 100%;
            display: flex;
            justify-content: space-between;
            gap: 1%;
            margin-bottom: 30px;

            &.w50 {
                width: 48%;

                @include small_down {
                    width: 100%;
                }
            }

            &.w33 {
                width: 32%;

                @include small_down {
                    width: 100%;
                }
            }

            &.end {
                padding-top: 10px;
            }

            @include small_down {
                flex-direction: column;

                > .form-item:last-child {
                    margin-top: 15px;
                }
            }
        }

        .form-row.captcha {
            .form-item {
                display: flex;
                flex-flow: wrap;
                background-color: transparent;
                height: 80px;

                .dropzone-fileUpload {
                    height: 77px;
                    padding: 28px 30px;
                    width: 100%;
                    min-height: 45px;
                    display: flex;
                    align-items: center;

                    .dz-details {
                        position: relative;
                        padding: 14px 0;
                        text-align: left;
                        opacity: 1;
                        background-color: transparent;
                    }

                    .dz-preview {
                        width: 100%;
                        max-width: unset;
                        min-height: unset;
                        margin: 0;
                        background: transparent !important;
                    }

                    .dz-image {
                        display: none;
                    }

                    .dz-filename {
                        padding-left: 0;
                        font-size: 20px;
                        /* color: var(--color-principal);*/
                        font-size: 15px;
                        width: 70%;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;

                        &:hover {
                            span {
                                border: 0;
                                background-color: transparent;
                            }
                        }

                        span {
                            padding-left: 0;
                        }
                    }

                    .icon-up {
                        top: 0;
                        bottom: 0;
                        margin: auto;
                        left: unset;
                        right: 20px;

                        &:before {
                            content: '';
                            position: absolute;
                            background-image: url('/dist/images/upload.svg');
                            background-repeat: no-repeat;
                            left: 0;
                            top: 0;
                            width: 28px;
                            height: 28px;
                            border-radius: 0;
                            box-sizing: border-box;
                            cursor: pointer;
                        }
                    }

                    .dz-remove {
                        margin-top: 0;
                        font-size: 0;
                        left: unset;
                        right: 20px;
                        z-index: 999;
                        height: 20px;
                        width: 20px;
                        margin: 0 auto;
                        position: absolute;
                        margin-right: 0;
                        display: flex;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                        right: 0;

                        &:before {
                            content: '';
                            position: absolute;
                            background-image: url('/dist/images/close.svg');
                            background-repeat: no-repeat;
                            display: flex;
                            top: 0;
                            bottom: 0;
                            margin: auto;
                            width: 20px;
                            height: 20px;
                            border-radius: 0;
                            box-sizing: border-box;
                            cursor: pointer;
                        }
                    }

                    .icon-close {
                        top: 0;
                        bottom: 0;
                        margin: auto;
                        left: unset;
                        right: 20px;
                        display: none;

                        &:before {
                            content: '';
                            position: absolute;
                            background-image: url('/dist/images/close.svg');
                            background-repeat: no-repeat;
                            left: 0;
                            top: 0;
                            width: 28px;
                            height: 28px;
                            border-radius: 0;
                            box-sizing: border-box;
                            cursor: pointer;
                        }
                    }
                }

                label.error {
                    @include mini {
                        top: 15px;
                    }
                }

                .input-group-prepend {
                    overflow: hidden;
                    margin-right: 10px;
                    background-color: var(--color-extra-04);
                    width: 48%;
                    height: 80px;
                    border: 1px solid #E8E8E8;
                }

                .form-item-captcha {
                    flex: unset;
                    background-color: var(--color-extra-04);
                    width: 48%;
                    margin: 0 auto;
                    margin-right: 0;
                }

                img {
                    height: 80%;
                    display: block;
                    width: 100%;
                    -o-object-fit: contain;
                    object-fit: contain;
                    box-sizing: border-box;
                    margin: 0 auto;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    position: absolute;
                }
            }
        }

        .form-item {
            flex: 1;
            display: flex;
            flex-flow: column;
            align-items: flex-start;

            .form-label {
                color: var(--brand-color);
                font-family: var(--secundary-font);
                font-size: 11px;
                letter-spacing: 0;
                line-height: 17px;
                padding: 0 15px;
            }

            &.icon {
                .form-input {
                    padding: 19px 15px 19px 50px;
                }
            }

            .icon-ma {
                position: absolute;
                bottom: 16px;
                left: 20px;
                font-size: 20px;
                z-index: 1;
                pointer-events: none;
            }

            .icon-ma.icon-calendar {
                bottom: 13px;
            }

            .check {
                width: 100%;
            }
        }

        .form-input {
            width: 100%;
            box-sizing: border-box;
            font-size: 18px;
            padding: 19px 15px 19px;
            color: var(--titles-color);
            border-bottom: 1px solid #E8E8E8;
            font-family: var(--main-font-family);
            background-color: transparent;

            &::-webkit-input-placeholder { /* Edge */
                color: #B0B0B0;
                font-size: 18px;
            }

            &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                color: #B0B0B0;
                font-size: 18px;
            }

            &::placeholder {
                color: #B0B0B0;
                font-size: 18px;
            }

            &.file-holder {
                opacity: 0;
                height: 0;
                padding: 0;
                pointer-events: none;
            }
        }

        .form-button {
            border-radius: 37.5px;
            background-color: var(--brand-color);
            width: fit-content;
            min-width: 250px;
            min-height: 50px;
            padding: 10px 30px;
            box-sizing: border-box;
            font-size: 16px;
            letter-spacing: 0;
            text-align: center;
            cursor: pointer;
            transition: all 350ms ease-in-out;
            color: var(--secundary-color);
            font-family: var(--secundary-font);
            margin: 0 auto;
            text-align: left;

            &:hover {
                opacity: 0.7;
            }
        }

        .selectric {
            border-radius: 37.5px;
        }

        .selectric .label {
            margin: 0 44px 0 50px;
            font-size: 14px;
            line-height: 63px;
            height: 63px;
        }

        select {
            width: 100%;
            height: 63px;
            border: 1px solid #e8e8e8;
            box-sizing: border-box;
            padding: 0 44px 0 50px;
            border-radius: 37.5px;
        }

        textarea.form-input {
            min-height: 100px;
            resize: none;
        }

        .check {
            text-align: center;
            justify-content: center;
            display: flex;
            align-items: center;
            color: var(--subtitles-color);
            font-size: 12px;
            letter-spacing: 0;
            line-height: 22px;

            label {
                padding-left: 20px;
                font-family: var(--secundary-font);
                color: var(--default-color);
                font-family: var(--secundary-font);
                font-size: 15px;
                letter-spacing: 0;
                line-height: 29px;
                display: flex;
                align-items: center;

                &.error {
                    top: -15px;
                }
            }

            .link-politica {
                padding-left: 5px;
                font-family: var(--secundary-font);
                color: var(--default-color);
                font-family: var(--secundary-font);
                font-size: 15px;
                letter-spacing: 0;
                line-height: 29px;
                display: flex;
                align-items: center;
                text-decoration: underline;
            }

            input {
                height: 22px;
                width: 22px;
            }
        }

        .input-escondido {
            display: none;
        }

        [type="checkbox"]:not(:checked),
        [type="checkbox"]:checked {
            opacity: 0;
            position: absolute;
        }

        [type="checkbox"]:not(:checked) + .label-rgpd:before,
        [type="checkbox"]:checked + .label-rgpd:before {
            content: '';
            box-sizing: border-box;
            position: absolute;
            left: 0;
            -webkit-transition: all .2s;
            transition: all .2s;
            width: 10px;
            height: 10px;
            box-sizing: border-box;
            border: 1px solid var(--brand-color);
        }

        [type="checkbox"]:not(:checked) + .label-rgpd:after,
        [type="checkbox"]:checked + .label-rgpd:after {
            content: "";
            background-image: url("/dist/images/checkOn.svg");
            position: absolute;
            left: 0;
            -webkit-transition: all .2s;
            transition: all .2s;
            width: 10px;
            height: 10px;
            box-sizing: border-box;
            background-size: cover;
        }

        [type="checkbox"]:not(:checked) + .label-rgpd:after {
            opacity: 0;
            transform: scale(0);
        }

        [type="checkbox"]:checked + .label-rgpd:after {
            opacity: 1;
            transform: scale(1);
        }
    }
}
