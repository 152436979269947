@import "../../assets/dev/css/_config.scss";

.ConteudoListaVideos {
    padding: 4% 0px;

    .textos {
        margin-bottom: 50px;
    }

    .desc {
        display: block;
        width: 100%;
        max-width: 850px;
        line-height: 150%;
        margin-bottom: 24px;
    }

    .texto {
        display: block;
        width: 100%;
        max-width: 850px;
        line-height: 150%;
    }

    .container {
        display: flex;
        flex-wrap: wrap;
        gap: 1.5%;
    }

    .play-video {
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 2;
        cursor: pointer;
    }

    .play-btn {
        display: flex;
        width: 80px;
        height: 80px;
        padding: 30px 30px 30px 50px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        top: 0;
        bottom: 0;
        right: 0;
        position: absolute;
        left: 0;
        margin: auto;
        z-index: 9;
        cursor: pointer;
        background: var(--color-default);
        box-sizing: border-box;
        border-radius: 100px;
        pointer-events: none;

        &:after {
            content: '';
            display: block;
            width: 34px;
            height: 28px;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            margin: auto;
            background-size: contain;
            top: 0;
            bottom: 0;
            right: 0;
            left: 3px;
            margin: auto;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url('/dist/images/play.svg');
        }
    }

    .video-card {
        margin-bottom: 16px;
        width: 32.33%;
        margin-bottom: 2%;

        @include small {
            width: 49%;
        }

        @include mini {
            width: 100%;
            margin-bottom: 40px;
        }

        &__link {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
        }

        &__img {
            padding-bottom: 279px / 396px * 100%;
            border-radius: 16px;
            margin-bottom: 16px;

            img {
                border-radius: 16px;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &__info {
            display: flex;
            padding: 0 8px;
            box-sizing: border-box;
            flex-direction: column;

            p,
            h4 {
                display: -webkit-box;
                max-width: 100%;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }

            h4 {
                margin-bottom: 4px;
                line-height: 130%;
            }

            p {
                line-height: 150%;
            }

            .link {
                margin-top: 16px;
                text-transform: uppercase;
                text-decoration: underline;
                color: #0B5C42;
            }
        }
    }
}

.featherlight.videoFeatherLight.videoFeatherLightIframe .featherlight-content {
    max-width: 90%;
    max-height: 90%;
    height: 100%;
    width: 100%;
    padding: 0 !important;

    .featherlight-inner {
        padding: 40px 40px 40px 40px !important;
    }

    .video {
        position: relative;
        border-radius: 16px;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        iframe {
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }

        video {
            height: 100%;
            width: 100%;
        }
    }

    .close {
        position: absolute;
        right: 5px;
        top: 5px;
        display: block;
        z-index: 99999;
        color: #FFF;
        cursor: pointer;
        width: 50px;
        height: 50px;

        maicontaghelper {
            width: 50px;
            height: 50px;
            display: flex;

            .icon-ma {
                width: 50px;
                height: 50px;
                display: flex;
            }
        }
    }
}
