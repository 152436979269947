@import "../../assets/dev/css/_config.scss";

.GuiaTuristicoDetalhe {
    .partilha-holder {
        padding-top: 24px;

        &:before {
            content: '';
            display: block;
            background: #D9D9D9;
            height: 1px;
            width: 100%;
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            top: 0;
        }

        .social_shares a,
        .social_shares div {
            background: #FFF;
        }
    }

    .banner-principal {
        padding-top: 25%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        padding-bottom: 55px;

        &:before {
            content: "";
            width: 100%;
            height: 30%;
            position: absolute;
            bottom: 0;
            left: 0;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.70) 78.04%);
        }

        .titulos {
            z-index: 1;
            display: flex;
            align-items: flex-end;
            justify-content: flex-start;
            gap: 18px;
            color: #fff;

            .titulo__info {
                min-height: 120px;
            }

            .breadcrumb {
                margin-bottom: 8px;
                display: flex;
                align-items: center;
                gap: 5px;
                color: #bdbcbc;

                a {
                    display: flex;
                    align-items: center;
                    color: #bdbcbc !important;
                    text-decoration: underline;
                    gap: 3px;

                    .icon-ma {
                        width: 12px;
                        transform: rotate(180deg);
                    }
                }
            }

            .icon {
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                background-color: var(--color-principal);
                height: 88px;
                width: 88px;
                align-self: center;

                img {
                    height: 100%;
                    width: 100%;
                    max-height: 48px;
                    max-width: 48px;
                    object-fit: contain;
                }

                img {
                    height: 48px;
                }

                &.mobile {
                    display: none;
                }

                @include mini {
                    display: none;

                    &.mobile {
                        display: flex;
                        width: 88px;
                        box-sizing: border-box;
                        margin-left: 20px;
                    }
                }
            }

            .page-title {
                line-height: 120%;

                @include mini {
                    display: block;
                    width: calc(100% - 20px);
                    margin: 0 auto;
                    margin-left: 20px;
                }
            }

            .localizacao {
                color: var(--color-extra);
                line-height: 130%;

                @include mini {
                    display: block;
                    width: calc(100% - 20px);
                    margin: 0 auto;
                    margin-left: 20px;
                }
            }

            .tags {
                display: flex;
                flex-flow: wrap;
                gap: 16px;
                padding-top: 15px;
                padding-bottom: 0;

                .tag-item {
                    display: flex;
                    padding: 10px 16px;
                    align-items: flex-start;
                    gap: 10px;
                    border-radius: 200px;
                    background: #FFF;
                    box-shadow: 0px 12px 24px -10px rgba(0, 0, 0, 0.04);
                    font-size: 14px;
                    line-height: 150%;
                    font-weight: 600;
                    color: var( --color-principal);
                    border: 1px solid var( --color-principal);
                }
            }
        }
    }

    .info-block {
        margin-top: 4.8%;
        margin-bottom: 4.8%;

        .info-block-iframe {
            display: block;
            width: 100%;
            height: 100%;

            iframe {
                width: 100%;
                height: 100%;
                min-height: calc(100vh - 150px);
            }
        }
    }
}
