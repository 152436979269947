@import "_config.scss";

/* -- DEBUG -- */
.debug-item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    outline-offset: -1px;
    outline: 1px solid #f00;
    z-index: 999;
    pointer-events: none;
}

.masterpage .overlay-menu {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    background-color: black;
    width: 100%;
    height: 100%;
    z-index: 9999;
    opacity: 0.6;
}

.masterpage.fixed {
    position: fixed;
}


@include small_down {
    .masterpage.fixed {
        position: relative;
    }
}

.masterpage .overlay-menu.open {
    display: block;
}

.debug-item:after {
    content: attr(data-debug);
    position: absolute;
    top: 0;
    left: 0;
    background-color: #f00;
    color: #ffF;
    font-size: 10px;
    padding: 5px 10px;
    z-index: 999;
    white-space: nowrap;
}

/* -- IMAGE SCALE -- */
.js-imagescale {
    opacity: 0;
    transition: opacity ease-in-out 300ms;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
    opacity: 1;
}

.js-imagescale.notrans {
    transition: none;
}

.js-imagescale.loaded {
    opacity: 1;
}

.js-imagescale[data-scale="best-fill"] {
    object-fit: cover;
}

.js-imagescale[data-scale="best-fit"] {
    object-fit: contain;
}

.js-imagescale[data-scale="best-fit-down"] {
    object-fit: contain;
}

.js-imagescale.loaded {
    opacity: 1;
}

.js-imagescale[data-scale="best-fit-down"] {
    object-fit: contain;
}

.js-imagescale[data-align="top"] {
    object-position: top;
}

.js-imagescale[data-align="bottom"] {
    object-position: bottom;
}

/* CHECK BROWSER */
#outdatedbrowser {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    box-sizing: border-box;
    line-height: 20px;
    padding: 3% 6%;
    margin: 0 auto;
    text-align: center;
    background: #fff;
    color: $title-color;
    font-size: 14px;
}

#outdatedbrowser.compact {
    padding: 5px 6%;
}

#outdatedbrowser .warning {
    display: inline-block;
    vertical-align: middle;
    width: 24px;
    height: 24px;
    margin-right: 10px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAAYFBMVEUAAAAaGhoICAgDAwMDAwMDAwMEBAQDAwMDAwMCAgIBAQEBAQEBAQEBAQEBAQEBAQEBAQEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAB3i/w4AAAAH3RSTlMABhAWGhweICIwTFJcanZ6g6Opq7G9z9HZ3+fv8/f9blz6EQAAAL1JREFUeNp1UtsWgyAMK24Kis77LgjN///lzmY59oU8pYSTQloSuHEPzGEfHWm4lSHgVUl9RJp9Y0zj54TYk+ABLHUu6gV4yH3wQAoDo//7R+TzrCD++qxYzvr2/txPtmAlcpzEvwM66ZPY0YhZHFqgFTpjpB1eqgqohHrsFNCQ4DgyaxCIYXL5emZmwFrYNiVoq2nSVtJccDVXzyVr6Xqu+qBNyaoPSiQiXJHoEK29QizFXhxUebTlZSiuzxegchM0ShebHwAAAABJRU5ErkJggg==) no-repeat center;
}

#outdatedbrowser .txt {
    display: inline-block;
    vertical-align: middle;
}

#outdatedbrowser .link {
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;
    padding: 5px 10px;
    font-size: 14px;
    color: #fff;
    background: $color-base;
}

#outdatedbrowser .close {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 2%;
    width: 24px;
    height: 24px;
    margin: auto;
    text-align: center;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAAeFBMVEUAAAD///8HBwcBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEAAAABAQEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACupXfzAAAAJ3RSTlMAAAJISkxOUFJUVlhaXF5gYmRmaGpsbnBydHh6fH6Di4+bn/f5+/2a3af0AAAAoklEQVR4Ab3R1w6CQBCF4WNXCiggFkWx7J73f0MnYxwK8c54Lv8vITsBoy/7NYxvAWyXGgZ3uvDTK/JqUJIugu5MPiYG2IrEkJ3I5xQNoPD0CXCUPkMbkIukB+lzdAGZiPQF+oCNdLfEEEqSPhuAPky+lvehkB4kniy6kHs9PhYpW6BP0tMjR+4aWEuPoQtF9ga1nv1e4FgZoE5hW1Xa/vHPX509EKW44FyrAAAAAElFTkSuQmCC) no-repeat center;
}

.small #outdatedbrowser span, .small #outdatedbrowser a {
    font-size: 12px;
}

.mini #outdatedbrowser span, .mini #outdatedbrowser a {
    font-size: 12px;
}

/* -- COOKIES -- */
.cookie-popup {
    display: block;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 9999;
    width: 410px;
    box-sizing: border-box;
    line-height: 20px;
    padding: 50px 40px 50px 50px;
    margin: 0 auto;
    text-align: left;
    background: var(--page-background);
    font-weight: 400;
    font-size: 14px;
    background-color: white;
    box-shadow: 0 10px 44px rgba(0,0,0,0.08);
    border-radius: 12px;
    overflow: hidden;

    &__buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .info {
        display: inline-block;
        vertical-align: middle;
        width: 44px;
        height: 44px;
        margin-right: 10px;
        margin-bottom: 10px;

        maicontaghelper, .icon-ma {
            height: 44px;
            width: 44px;
        }
    }

    .info.icon-ma.icon-icon_cookies {
        display: inline-block;
        color: var(--color-default);
        font-size: 35px;
    }

    .infoTitulo {
        @include font(25px,var(--color-default),800, var(--main-font-family));
        line-height: 23px;
        display: block;
        margin-top: 0px;
        margin-bottom: 20px;
        max-width: 285px;
    }

    .txt {
        display: inline-block;
        vertical-align: middle;
        max-width: 285px;
        margin-bottom: 50px;
        color: var(--color-default);
        font-family: var(--main-font-family);
    }

    .link {
        display: inline-block;
        @include font(14px,var(--color-default),800, var(--main-font-family));
        text-decoration: none;
        vertical-align: middle;
        margin: 0 5px;
        height: 60px;
        line-height: 60px;
    }

    .accept {
        display: flex;
        vertical-align: middle;
        min-width: 135px;
        height: 52px;
        line-height: 52px;
        text-align: center;
        opacity: 1;
        transition: all 400ms ease-in-out;
        background-color: var(--color-alternativa);

        &:hover {
            opacity: 0.7;
        }
    }

    @include mini {
        width: auto;
        margin: 0 auto;
        padding: 40px 40px 40px 40px;
        max-width: 450px;

        .txt {
            margin-bottom: 10px;
            display: block;
        }

        .accept {
            float: none;
            height: 40px;
            line-height: 34px;
        }

        .infoTitulo {
            font-size: 22px;
            margin-top: 10px;
            margin-bottom: 10px;
        }

        .link {
            height: 40px;
            line-height: 40px;
            display: block;
        }
    }
}


/* -- LEGAL POPUP -- */
.legal-popup {
    width: 100vw;
    min-height: 100vh;
    height: auto;
    font-family: var(--secundary-font);
    max-width: 100%;

    $widthAvisoLegal: 83%;
    $maxWidthAvisoLegal: 800px;

    button {
        background-color: unset;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 25px;
        display: flex;
        align-items: center;
        margin: 0 auto;
        color: white;
        opacity: 1;
        transition: all 400ms ease-in-out;
        font-family: var(--secundary-font);

        &:hover {
            opacity: .7;
            transition: all 400ms ease-in-out;
        }

        .icon-ma {
            color: var(--brand-color);
            background: white;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 34px;
            margin-left: 15px;
        }
    }

    &:before {
        content: "";
        height: 4px;
        width: 100%;
        position: absolute;
        top: 0;
        z-index: 2;
        background-color: var(--brand-color);
    }

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        position: absolute;
    }

    .wrapper {
        display: flex;
        flex-direction: column;
    }

    .logo-holder {
        display: block;
        height: 70px;
        width: 166px;
        margin: 40px auto;

        img {
            width: 100%;
        }
    }

    .bloco {
        background-color: var(--brand-color);
        color: white;
        width: $widthAvisoLegal;
        max-width: $maxWidthAvisoLegal;
        margin: 0 auto;
        flex-direction: column;
        padding: 7%;
        box-sizing: border-box;
        text-align: center;

        @include small_down {
            padding: 73px 24px 60px 24px;
        }

        .titulo {
            @include small_down {
                font-size: 35px;
                line-height: 35px;
            }
        }

        .texto {
            display: block;
            padding-top: 30px;
            padding-bottom: 80px;
            font-weight: 300;

            @include small_down {
                font-size: 16px;
                line-height: 25px;
                padding-bottom: 62px;
            }
        }
    }

    .nota-cookies {
        color: white;
        width: $widthAvisoLegal;
        max-width: $maxWidthAvisoLegal;
        margin: 0 auto;
        text-align: center;
        padding-top: 33px;
        padding-bottom: 15px;
        padding-left: 2%;
        padding-right: 2%;
        box-sizing: border-box;

        a {
            border-bottom: 1px solid white;
        }

        .nota-copypright {
            padding-top: 20px;
            opacity: 0.8;
        }
    }
}

/* -- VALIDATION ERRORS -- */
.validator {
    position: absolute;
    right: -5px;
    top: 20px;
    width: 20px;
    height: 20px;
    text-align: center;
}

.validator .erroMark {
    cursor: pointer;
    z-index: 99;
    position: relative;
    width: 20px;
    height: 20px;
    background-color: var(--color-alternativa);
    text-align: center;
    color: var(--color-principal);
    font-weight: 300;
    font-size: 20px;
    display: block;
    line-height: 29px;
    border-radius: 50%;
}

.validator .erroDetail {
    display: none;
    white-space: nowrap;
    width: auto;
    padding: 5px 22px 5px 15px;
    background-color: var(--color-alternativa);
    z-index: 98;
    color: var(--color-principal);
    position: relative;
    font-weight: 300;
    font-size: 12px;
    margin-top: -22px;
    text-align: left;
    border-radius: 10px;
    height: 25px;
    position: absolute;
    right: 0;
    box-sizing: border-box;
    line-height: 16px;
    font-family: var(--main-font-family);
}

.validator:hover .erroDetail {
    display: block;
}

.overlay:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background: linear-gradient(270deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.44) 51.56%, rgba(0,0,0,0.4) 100%);
    z-index: 1;
}

.ma-modulo.empty {
    min-height: 200px;
    border: 1px solid #ddd;
}

.ma-modulo.empty:after {
    content: 'Isto é temporário. Desaparece quando tiver html dentro do modulo: ' attr(class);
    position: absolute;
    top: 0;
    left: 0;
    font-size: 12px;
    color: #000;
}

maicontaghelper,
.icon-ma {
    width: 20px;
    height: 20px;
    transition: all 300ms ease-in-out;
}

/* ESTRUTURA BASE */
.masterpage {
    margin: 0 auto;
    position: relative;
    width: 100%;
    display: block;
    z-index: 9;
    overflow: hidden;
}

.masterpage.fixed {
    position: fixed;
}

/*.masterpage .page {
    position: relative;
    z-index: 1;
    display: block;
    clear: both;
    width: 100%;
    margin-top: 150px;
}*/

.masterpage .hide {
    display: none !important;
}

.masterpage .wfull {
    width: 92%;
    max-width: 1750px;
    margin: 0 auto;
}

.masterpage .wmed {
    width: 92%;
    max-width: 1513px;
    display: block;
    margin: 0 auto;
}

.masterpage .wmin {
    width: 92%;
    max-width: 1252px;
    display: block;
    margin: 0 auto;
}

.sticky-wrapper {
    position: sticky;
    top: 20px;
}

/* -- BOOTSTRAP DATEPICKER -- */
.dropdown-menu {
    font-family: var(--secundary-font);
    padding: 10px;
}

body .datepicker {
    padding: 5px 20px 15px !important;
    border-radius: 4px !important;
    border-color: transparent !important;
    box-shadow: 0 18px 32px -2px rgba(0,0,0,0.06);

    .datepicker-switch {
        font-size: 16px;
        font-weight: bold;
        color: #070707;
        font-family: var(--main-font-family);
        letter-spacing: 0;
        line-height: 20px;
        text-align: center;
        padding: 8px 0 10px;
    }

    .prev:after,
    .next:after {
        content: url(/dist/images/arrowd.svg);
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 6px;
        width: 100%;
        height: 100%;
        z-index: 1;
        background-color: #fff;
    }

    .prev:after {
        content: url(/dist/images/arrowd.svg);
    }

    .prev:hover,
    .next:hover {
        background: none !important;
    }

    td {
        width: 35px;
        height: 35px;
        border-radius: 9px;
        font-family: var(--secundary-font);
        font-size: 16px;
        letter-spacing: 0;
        line-height: 23px;

        &.selected,
        &.active {
            background-color: #F8F4F2 !important;
            color: var(--brand-color) !important;
            text-shadow: none;
            background-color: #9e9e9e;
            background-image: none !important;
            filter: none !important;
            border-color: none !important;
            border-color: none !important;
            border-radius: 9px;
            font-weight: 600;
        }

        &.today {
            background-image: none !important;
        }
    }



    th {
        width: 20px;
        height: 35px;
        border-radius: 4px;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 18px;
        color: #616161;
        font-family: var(--secundary-font);
    }

    table tr td.active,
    table tr td.active:hover,
    table tr td.active.disabled,
    table tr td.active.disabled:hover {
        text-shadow: none;
    }
}

body .selectric-wrapper {

    .selectric-items li {
        font-size: 14px;
        font-family: var(--secundary-font);
        border: 0;
        font-weight: 400;
        color: var(--default-color);
    }

    .selectric-items li.highlighted {
        background: var(--page-background);
        font-weight: 600;
    }
}

/* -- SWIPER BUTTONS -- */
.ma-modulo .swiper-button-prev,
.ma-modulo .swiper-button-next {
    position: relative;
    left: 0;
    right: 0;
    margin: 0;
    height: 66px;
    width: 66px;
    font-size: 16px;
    border-radius: 50%;
    color: var(--brand-color);

    .icon-ma {
        height: 100%;
        color: var(--brand-color);
        display: flex;
        align-items: center;
    }

    &:after {
        display: none;
    }
}

.ma-modulo .swiper-button-prev .icon-ma {
    transform: rotate(180deg);
}

.ma-modulo .swiper-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
}

.ma-modulo .swiper-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 40px !important;

    .swiper-pagination-bullet-active {
        background: var(--secundary-color);
    }

    .swiper-pagination-bullet {
        width: 6px;
        height: 6px;
        margin: 0 12px;
        border: 1px solid var(--secundary-color);
        box-sizing: border-box;
    }
}

@include normal {
    .masterpage .page {
        margin-top: 100px;
    }
}

@include small {
    .masterpage .page {
        margin-top: 100px;
    }
}

@include mini {
    .masterpage .page {
        margin-top: 100px;
    }

    .masterpage .wmin {
        width: 85%;
        max-width: 1232px;
        display: block;
        margin: 0 auto;
    }
}


.feathermsg {
    text-align: center;
}

.feathermsg .icon-ma {
    font-size: 50px;
    display: block;
    margin-bottom: 15px;
    color: var(--color-principal);
}


.featherlight .featherlight-inner p {
    display: block;
    text-align: center;
    margin: 0 0 40px 0;
    color: var(--color-titulos) !important;
    font-family: var(--main-font-family);
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: none;
    font-weight: normal;
}


.featherlight.newsletter .newsTitulo {
    color: black !important;
    font-family: var(--main-font-family);
    margin-bottom: 10px;
}

.featherlight.newsletter .newsSubtitulo {
    margin-bottom: 30px;
}

.featherlight.newsletter .titulos {
    display: flex;
    flex-flow: column;
}

.featherlight.newsletter label.error {
    position: absolute;
    right: 20px;
    font-size: 11px;
    font-weight: 600;
    color: red;
    top: 0;
    z-index: 1;
    font-family: var(--secundary-font);
}

.featherlight.newsletter .btn_close,
.featherlight.form-avaliacao-vinho .btn_close {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 20px;
    cursor: pointer;
}

.featherlight.newsletter .btn_close .icon-ma,
.featherlight.form-avaliacao-vinho .btn_close .icon-ma {
    font-size: 25px;
    width: 40px;
    height: 40px;
    background: white;
    display: flex;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -20px;
    right: -20px;
    z-index: 2;
    color: var(--brand-color);
    cursor: pointer;
}


.feathermsg .title {
    width: 100%;
    text-align: center;
    margin: 0 auto;
    @include font(30px, var(--color-titulos), var(--main-font-family));
    line-height: 37px;
    text-transform: uppercase;
}

.featherlight .featherlight-inner {
    padding: 45px 40px 15px 40px !important;
}

.featherlight .featherlight-content {
    /*    background: var(--color-principal)!important;*/
    background: white !important;
    padding-bottom: 40px !important;
}

.featherlight .featherlight-inner {
    /*    padding: 15px 10px 5px 10px;*/
    padding: 75px 50px 95px 50px;
}

.featherlight .comp-main-button {
    margin: 0 auto;
}

.feathermsg p {
    text-align: center;
    @include font(17px, var(--color-titulos), normal, var(--alt-font-family));
    line-height: 17px;
    letter-spacing: 1px;
    text-transform: uppercase;
}
/*RESPONSIVE FONTS*/
@include mini {
    .featherlight .featherlight-inner {
        padding: 35px 20px 0px 20px !important;
    }
}
