@import "../_config.scss";

.paginacao {
    padding: 50px 0 50px;
    width: 100%;
    display: flex;
    justify-content: flex-end;

    @include small_down {
        justify-content: center;
    }

    &__botoes {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        @include small {
        }

        @include mini {
            align-items: unset;
            justify-content: center;
            width: 100%;
            gap: 20px;
        }

        .main-btn {
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
            padding: 10px 26px;
            gap: 16px;


            &.disabled {
                color: #6D788E;
                pointer-events: none;
            }

            &.prev {
                .ico-holder {
                    transform: rotate(180deg);
                    display: flex;
                    align-items: center;
                }
            }

            &.next {
                .ico-holder {
                    transform: rotate(0);
                    display: flex;
                    align-items: center;
                }
            }

            .ico-holder {
                /*   background: #FFFFFF;
                border: 1px solid #E8E9E9;
                border-radius: 8px;
                transform: matrix(-1, 0, 0, 1, 0, 0);
                width: 42px;
                height: 42px;*/
                display: flex;
                align-items: center;
                justify-content: center;

                .vue.icon-arrow {
                    background-image: url(/dist/images/areacliente/arrow.svg);
                }

                maicontaghelper, .icon-ma {
                    width: 30px;
                    height: 20px;
                }
            }
        }

        .sep:before {
            content: "";
            height: 42px;
            background-color: #E8E9E9;
            width: 1px;
            display: block;
        }

        ul {
            display: flex;
            justify-content: center;
            align-items: baseline;
            gap: 4px;

            @include small_down {
                padding-top: 10px;
                gap: 9px;
            }


            li {


                span,
                a {
                    padding: 0 2px 6px;
                    cursor: pointer;
                    transition: all 300ms ease-in-out;
                    color: #202326;

                    &:hover, &.active {
                        color: var(--color-principal);
                    }
                }
            }
        }
    }
}