@import "../../assets/dev/css/_config.scss";

.TextoPoliticas {
    padding-top: 200px;
    padding-bottom: 100px;

    .container {
        &__titulo {
            .comp-titulo-icon {
                display: block;
                margin-bottom: 28px;

                .icon-ma {
                    color: var(--brand-color);
                    font-size: 30px;
                }
            }



            .titulo {
                display: block;
                margin-bottom: 30px;
            }
        }

        &__subtitulo {
        }
    }
}
