@import "../../assets/dev/css/_config.scss";

.PatrimonioListagem {
    background-color: var(--color-alternativa);
    padding-top: 95px;
    padding-bottom: 45px;

    .no-results {
        padding: 30px 0;
    }

    .tags {
        display: flex;
        flex-flow: wrap;
        gap: 16px;
        padding-bottom: 32px;

        .tag-item {
            display: flex;
            padding: 10px 16px;
            align-items: flex-start;
            gap: 10px;
            border-radius: 200px;
            border: 1px solid #F7F7F7;
            background: #FFF;
            box-shadow: 0px 12px 24px -10px rgba(0, 0, 0, 0.04);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;

            &.active {
                font-weight: 500;
                color: var( --color-principal);
                border: 1px solid var( --color-principal);
            }
        }
    }

    &__wrapper {
    }

    &__titulos {
        display: flex;
        flex-direction: column;
        gap: 6px;
        padding-bottom: 30px;
        padding-top: 25px;
        max-width: 1050px;
        width: 92%;

        .titulo {
            display: block;
            font-family: var(--alt-font-family);
            font-weight: 300;
            line-height: 120%;
        }

        .subtitulo {
            line-height: 130%;
        }
    }

    &__lista {
        display: flex;
        flex-wrap: wrap;
        gap: 2%;

        .comp-PatrimonioCard {
            margin-bottom: 30px;
            width: 23.5%;
        }

        @include large {
            .comp-PatrimonioCard {
                margin-bottom: 30px;
                width: 32%;
            }
        }

        @include normal {
            .comp-PatrimonioCard {
                margin-bottom: 30px;
                width: 32%;
            }
        }

        @include small {
            .comp-PatrimonioCard {
                margin-bottom: 30px;
                width: 49%;
            }
        }

        @include mini {
            .comp-PatrimonioCard {
                margin-bottom: 30px;
                width: 100%;
            }
        }
    }
}