@import "../../assets/dev/css/_config.scss";

.ConteudoTexto {
    padding: 70px 0;

    .textos {
        width: 100%;
        max-width: 850px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        box-sizing: border-box;
    }

    .desc {
        line-height: 150%;
    }

    .texto {
        line-height: 150%;
    }
}