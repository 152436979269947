@import "../_config.scss";

.partilha-holder {
    .comp-SocialShare {
        display: flex;
        flex-flow: column;
        align-items: flex-start;
        padding-top: 0;
    }

    .social_shares {
        padding-top: 24px;
        display: flex;
        gap: 16px;
        flex-flow: wrap;

        a, div {
            padding: 13px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            background: #F7F7F7;
            transition: 300ms all ease-in-out;
            opacity: 1;
            cursor: pointer;

            .lbl {
                display: none;
            }

            &:hover {
                opacity: 0.7;
            }

            .tooltip {
                display: none;
                position: absolute;
                white-space: nowrap;
                bottom: -25px;
                font-weight: 300;
                font-size: 12px;
                color: var(--color-principal);
            }
        }
    }
}
