@import "../../assets/dev/css/_config.scss";

.HomepageVisitar {
    overflow: hidden;
    padding-bottom: 4%;

    &.grey-bg {
        background-color: var(--color-alternativa);
    }

    &__topo {
        display: flex;
        justify-content: space-between;
        padding-top: 40px;
        padding-bottom: 25px;
        align-items: center;


        .swiper-button-next,
        .swiper-button-prev {
            background-color: none;
            box-shadow: none;
        }


        .navigation {
            display: flex;

            maicontaghelper, svg {
                width: 30px;
                height: 20px;
            }
        }
    }

    &__lista {
    }

    .swiper-container {
        overflow: hidden;
    }

    .swiper-slide {
        width: 25%;
    }
}